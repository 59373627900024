<template>
  <v-app-bar elevate-on-scroll app color="grey lighten-5" elevation="4" outlined height="78">
    <div class="center-me">
      <v-img
        class="pa-5"
        v-bind:src="require('@/assets/FillMeFormsTitle.png')"
        @click="onMainLogoClick"
        max-height="50"
        max-width="143"
      >
      </v-img>
    </div>

    <v-spacer></v-spacer>


    <!--
          material design icons list 
          https://vuetifyjs.com/en/features/icon-fonts/#material-design-icons
    -->
    <v-btn
      icon
      @click="onDevIconClick()"
      color="brown"
      v-if="SHOW_dev_icon_button"
    >
      <v-icon>
        mdi-dev-to
      </v-icon>
    </v-btn>


    <!--{{ acct_icon_text }}-->
    <v-btn
      icon
      @click="onAcctIconClick()"
      color="indigo"
    >
      <v-icon>
        mdi-account-box
      </v-icon>
    </v-btn>

    <v-btn
      icon
      @click="onLogoutIconClick()"
      color="darkgrey"
      v-show="SHOW_logout_icon_button"
    >
      <v-icon>
        mdi-logout
      </v-icon>
    </v-btn>

    <!--
      color="indigo"
      color="dark-grey"
      style="color:indigo"
      style="color:dark-grey"
      style="color:darkgrey"      
      mdi-heart
      mdi-account-box
      mdi-logout
    <v-btn icon>
      {{ acct_icon }}
      <v-icon>mdi-magnify</v-icon>
    </v-btn>
    -->

  </v-app-bar>

</template>

<style scoped>
.center-me {
  position: absolute;
  margin-left: 50%;
  transform: translate(-50%, 0);
}
</style>


<script>

// UI Enhacements
// https://codingbeautydev.com/blog/vuetify-app-bar/

import { getStateMgmtStore } from '../../../stores/ux_state_store.js';

import { FMF_URLs } from '../../../router/index.js'
import { RoutesMap } from '../../../router/index.js'
import { fmf_event, fmf_error_events } from '../../../utils/AnalyticsHandler.js'

import { env } from '../../../Globals'
//import { getNetworkEnv } from '../../../Globals'

//import { ref, set } from "firebase/database";
//import { onValue, get } from "firebase/database";
//import { getAuth } from "firebase/auth";

// Templates
// https://vuetifyjs.com/en/components/app-bars/


import Bugsnag from '@bugsnag/js'


export default {
  name: 'NavBar',

  //////////////////////////////////////////////////////
  ///////////////// REACTIVE VARIABLES /////////////////
  //////////////////////////////////////////////////////
  data() {
    return {
      CLASS_NAME: "NavBar",

      //acct_icon: null,

      //SHOW_logout_icon_button: false,
      //SHOW_logout_icon_button: true,
      //SHOW_logout_icon_button: fmfPiniaStore.getAuth!=null,

      //auth: fmfPiniaStore.getAuth,

      //isAuth: fmfPiniaStore.getIsAuth,

      //logout_icon_text: "C",
      //acct_icon_text: null,

      /*
      navlinks: [
        //{ icon: 'mdi-gavel', title: 'Solutions', route: '/'},
        //{ icon: 'mdi-thumb-up', title: 'Benefit', route: '/' },
        { icon: 'mdi-help-box', title: 'FAQ', route: '/faq'},
        { icon: 'mdi-information-outline', title: 'About', route: '/about'},
        { icon: 'mdi-account-box', title: 'Account', route: '/login'},
        //{ icon: 'mdi-account-box', title: 'Login/Sign Up', route: '/login'},
        //{ icon: 'mdi-account-box', title: 'Dashboard', route: '/dashboard'}
      ],
      */

    }
  },
  computed: {
    /*
    acct_icon_text(){
      //console.log("computed: icon_text")
      
      //let num = Math.random(0,1)
      //console.log(num)

      let text;
      //if (num > 0.5) {
      if (getStateMgmtStore(this.$piniaInstance).getCount % 2 == 0) {
        text = "A"
      } else {
        text = "B"
      }
      return text
      
      return getStateMgmtStore(this.$piniaInstance).getCount
    },
    */
    SHOW_dev_icon_button(){
      //PROD version
      const display = this.$currentEnv != env.PROD

      return display
    },
    SHOW_logout_icon_button() {
      //console.log("computed: SHOW_logout_icon")
      return getStateMgmtStore(this.$piniaInstance).get_is_user_logged_in;
      
    },
    
  },
  watch: {
    /*
    auth: function (newVal, oldVal) {
      console.log('changed: ', newVal, ' | was: ', oldVal)
    },
    logout_icon_text: function (newVal, oldVal) {
      console.log('changed: ', newVal, ' | was: ', oldVal)
    },
    */
  },

  //////////////////////////////////////////////////////
  //////////////// LIFE-CYCLE FUNCTIONS ////////////////
  //////////////////////////////////////////////////////
  created: function () {
    //console.log(this.CLASS_NAME, "-- created")

    /*
    const databaseReference = ref(
      this.$firebaseDatabase_ux,
      //"user_id/" + this.$firebaseAuthInstance.currentUser.uid
      "user_id/" + "cr7-userId"
    );
    console.log("database referece = ")
    console.log(databaseReference)
    */


    /*
    // load firebase data with change listener
    onValue(
      databaseReference,
      (result) => {
        console.log("firebase-database: onChange()")
        // always return ALL objects
        // not called if updated to SAME objects

        if (result.exists()) {
          //console.log(result)
          const data = result.val();
          console.log(data)
        } else {
          console.warn("no firebase data")
        }

      });
    console.log("onValue() end")
    */



    /*
    this.$firebaseAuthInstance.onAuthStateChanged(function (firebaseUser) {
      console.log("nav_bar: OnAuthStateChange()")

      let navlinks = []
      if (firebaseUser) {
        console.warn("firebaseUser = ")
        console.warn(firebaseUser)

        //this.acct_icon = "mdi-heart"

        navlinks = [
          { icon: 'mdi-currency-usd', title: 'Pricing', route: '/pricing'},
          { icon: 'mdi-help-box', title: 'FAQ', route: '/faq'},
          { icon: 'mdi-information-outline', title: 'About', route: '/about'},
          { icon: 'mdi-account-box', title: 'Account', route: '/dashboard'},
          { icon: 'mdi-logout', title: 'Logout', route: '/'}
        ]

      } else {
        console.warn("NO firebaseUser")

        //this.acct_icon = "mdi-magnify"

        navlinks = [
          //{ icon: 'mdi-currency-usd', title: 'Pricing', route: '/pricing'},
          //{ icon: 'mdi-help-box', title: 'FAQ', route: '/faq'},
          //{ icon: 'mdi-information-outline', title: 'About', route: '/about'},
          //{ icon: 'mdi-account', title: 'Signup', route: '/signup' },
          //{ icon: 'mdi-login', title: 'Login', route: '/login'}
        ]

      }
    });
    */

  },

  //////////////////////////////////////////////////////
  ////////////////////// FUNCTIONS /////////////////////
  //////////////////////////////////////////////////////
  methods: {
    onDevIconClick(){
      console.log("onDevIconClick()")
      this.$router.push(RoutesMap.DEV_1.path)  
    },
    onLogoutIconClick(){
      console.log("onLogoutIconClick()")

      Bugsnag.leaveBreadcrumb(
        "NavBar: onLogoutIconClick",
      )

      fmf_event(
        "NavBar_LogoutIcon",
      )
            
      const fmfPiniaStore = getStateMgmtStore(this.$piniaInstance)
      const self = this      

      this.$firebaseAuthInstance.signOut().then(
        function () {
          console.warn('signed out success');

          if (self.$currentEnv == env.PROD) {
            window.location = FMF_URLs.HOME.url
          } else {
            self.$router.push(RoutesMap.DEV_1.path)
          }
          
          // don't have 'this.$piniaInstance' because we are inside inner class
          // "this" does not exist in inner class
          fmfPiniaStore.$reset();
          localStorage.clear();

        }, // ended sign-out success block
        function (error) {
          console.error('Firebase sign out error', error);
          console.error("FMF_INCIDENT")
          Bugsnag.notify(error)
          
          window.location = FMF_URLs.HOME.url

          fmf_error_events(
            "_error",
            "firebase",
            {
              firebase: error
            }
          )

          fmfPiniaStore.$reset();
          localStorage.clear();

        } // ended sign-out error block

      ) // ended all of sign-out block
      
      // nothing here

    },
    onAcctIconClick() {
      console.log("onAcctIconClick()")
      
      Bugsnag.leaveBreadcrumb(
        "NavBar: onAcctIconClick",
      )
      
      const is_user_logged_in = getStateMgmtStore().get_is_user_logged_in

      fmf_event(
        "NavBar_AccountIcon",
        {
          is_user_logged_in: is_user_logged_in,
        }
      )
      
      
      if (is_user_logged_in) {
        console.log("go to JOURNEY PAGE")
        
        this.$router.push(RoutesMap.JOURNEY.path).catch(
          (error) => { 
            if (error != null && error.name != 'NavigationDuplicated') {
              console.error(error)
              console.error("FMF_INCIDENT")
              Bugsnag.notify(error)
            } else {
              // "NavigationDuplicated" is okay
              console.log(error.name)
            }
          }
        );

      } else {
        console.log("go to CREATE ACCT PAGE")

        //this.$router.push(RoutesMap.SIGN_UP.path).catch(
        this.$router.push(RoutesMap.LOGIN.path).catch(          
          (error) => {
            if (error != null && error.name != 'NavigationDuplicated') {
              console.error(error)
              console.error("FMF_INCIDENT")
              Bugsnag.notify(error)

            } else {
              // "NavigationDuplicated" is okay
              console.log(error.name)
            }
          }
        );

      }


    },
    onMainLogoClick() {
      console.log("onMainLogoClick")
      //console.log(FMF_URLs.HOME.url)

      fmf_event(
        "NavBar_MainLogo",
      )

      window.location = FMF_URLs.HOME.url
    },

  },

}

</script>
