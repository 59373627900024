// ENUM class for PartyType

export const EnumPartyType = Object.freeze({
    
    Plaintiff: { type: "Plaintiff" },
    Defendant: { type: "Defendant" },
    Individual: { 
        type: "Individual",
        lower_plural: "individuals",
        lower_singular: "an individual",
    },
    Business: { 
        type: "Business",
        lower_plural: "businesses",
        lower_singular: "a business",

    },
    Other: { type: "Other" },
    Both: { type: "Both" },

});