/**
 * FillMeForms API
 * FillMeForms API Specs Used for client-server communication 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The GenerateFileRequest model module.
 * @module model/GenerateFileRequest
 * @version 0.0.1
 */
class GenerateFileRequest {
    /**
     * Constructs a new <code>GenerateFileRequest</code>.
     * info to create the PDF download link
     * @alias module:model/GenerateFileRequest
     * @param documentType {module:model/GenerateFileRequest.DocumentTypeEnum} document type to generate
     */
    constructor(documentType) { 
        
        GenerateFileRequest.initialize(this, documentType);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, documentType) { 
        obj['document_type'] = documentType;
    }

    /**
     * Constructs a <code>GenerateFileRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/GenerateFileRequest} obj Optional instance to populate.
     * @return {module:model/GenerateFileRequest} The populated <code>GenerateFileRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new GenerateFileRequest();

            if (data.hasOwnProperty('document_type')) {
                obj['document_type'] = ApiClient.convertToType(data['document_type'], 'String');
            }
            if (data.hasOwnProperty('expiration_in_minutes')) {
                obj['expiration_in_minutes'] = ApiClient.convertToType(data['expiration_in_minutes'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * document type to generate
 * @member {module:model/GenerateFileRequest.DocumentTypeEnum} document_type
 */
GenerateFileRequest.prototype['document_type'] = undefined;

/**
 * Sets how long the downloadable link will availiable for in minutes
 * @member {Number} expiration_in_minutes
 */
GenerateFileRequest.prototype['expiration_in_minutes'] = undefined;





/**
 * Allowed values for the <code>document_type</code> property.
 * @enum {String}
 * @readonly
 */
GenerateFileRequest['DocumentTypeEnum'] = {

    /**
     * value: "DemandLetter"
     * @const
     */
    "DemandLetter": "DemandLetter",

    /**
     * value: "AgreementLetter"
     * @const
     */
    "AgreementLetter": "AgreementLetter"
};



export default GenerateFileRequest;

