import { firebaseAuthInstance } from '../main.js';
import { getStateMgmtStore } from '../stores/ux_state_store.js';

export function fmf_Refresh_Token(
    onRefreshed
) {
    console.log("fmf_Refresh_Token()")
    
    const user = firebaseAuthInstance.currentUser
    console.log("user = ")

    if (user != null) {
        // don't show everything for safety
        //console.warn(user)
        console.warn(user.email)

        user.getIdToken().then(function (token) {
            console.log("getIdToken() completed")
            //console.warn("token = ", token)

            const currentToken = getStateMgmtStore().get_auth_token
            //console.warn("currentToken = ", currentToken)

            const do_tokens_match = (currentToken == token)
            console.warn("do_tokens_match = ", do_tokens_match)

            if (!do_tokens_match) {
                getStateMgmtStore().update_auth_token(token)
                console.log("token updated")
            } else {
                console.log("did NOT update token")
            }
            return onRefreshed
            //return onRefreshed()
            //return onRefreshed(token)
        })
    } else {
        console.log("user is NULL")
        return onRefreshed
        //return onRefreshed()
        //return onRefreshed(token)
        //return onRefreshed(null)
    }

    //const token = await user.getIdToken()
    //onResolve(token)

    /*
    user.getIdToken().then(function (token) {
        //fmfAuthenticate(token)
    })
    */
    
}