/**
 * FillMeForms API
 * FillMeForms API Specs Used for client-server communication 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CheckAuthStatusRequest from '../model/CheckAuthStatusRequest';
import GenerateFileRequest from '../model/GenerateFileRequest';
import NetworkErrorResponse from '../model/NetworkErrorResponse';
import NetworkResponse from '../model/NetworkResponse';
import SendCustomEmailRequest from '../model/SendCustomEmailRequest';
import SendEmailRequest from '../model/SendEmailRequest';
import WaitlistEntry from '../model/WaitlistEntry';

/**
* Default service.
* @module api/DefaultApi
* @version 0.0.1
*/
export default class DefaultApi {

    /**
    * Constructs a new DefaultApi. 
    * @alias module:api/DefaultApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the addWaitlist operation.
     * @callback module:api/DefaultApi~addWaitlistCallback
     * @param {String} error Error message, if any.
     * @param {module:model/NetworkResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * record a waitlist record
     * @param {module:model/WaitlistEntry} waitlistEntry 
     * @param {module:api/DefaultApi~addWaitlistCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/NetworkResponse}
     */
    addWaitlist(waitlistEntry, callback) {
      let postBody = waitlistEntry;
      // verify the required parameter 'waitlistEntry' is set
      if (waitlistEntry === undefined || waitlistEntry === null) {
        throw new Error("Missing the required parameter 'waitlistEntry' when calling addWaitlist");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = { 'Authorization': 'Bearer ' + localStorage.getItem('token')
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = NetworkResponse;
      return this.apiClient.callApi(
        '/v1/waitlist', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the checkAuthStatus operation.
     * @callback module:api/DefaultApi~checkAuthStatusCallback
     * @param {String} error Error message, if any.
     * @param {module:model/NetworkResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * confirm user's authentication status (ie whether firebase token is valid)
     * @param {module:model/CheckAuthStatusRequest} checkAuthStatusRequest 
     * @param {module:api/DefaultApi~checkAuthStatusCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/NetworkResponse}
     */
    checkAuthStatus(checkAuthStatusRequest, callback) {
      let postBody = checkAuthStatusRequest;
      // verify the required parameter 'checkAuthStatusRequest' is set
      if (checkAuthStatusRequest === undefined || checkAuthStatusRequest === null) {
        throw new Error("Missing the required parameter 'checkAuthStatusRequest' when calling checkAuthStatus");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = { 'Authorization': 'Bearer ' + localStorage.getItem('token')
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = NetworkResponse;
      return this.apiClient.callApi(
        '/v1/check-auth-status', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the generateFile operation.
     * @callback module:api/DefaultApi~generateFileCallback
     * @param {String} error Error message, if any.
     * @param {module:model/NetworkResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Create a download file link
     * @param {module:model/GenerateFileRequest} generateFileRequest 
     * @param {module:api/DefaultApi~generateFileCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/NetworkResponse}
     */
    generateFile(generateFileRequest, callback) {
      let postBody = generateFileRequest;
      // verify the required parameter 'generateFileRequest' is set
      if (generateFileRequest === undefined || generateFileRequest === null) {
        throw new Error("Missing the required parameter 'generateFileRequest' when calling generateFile");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = { 'Authorization': 'Bearer ' + localStorage.getItem('token')
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = NetworkResponse;
      return this.apiClient.callApi(
        '/v1/generate-file', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the sendCustomEmail operation.
     * @callback module:api/DefaultApi~sendCustomEmailCallback
     * @param {String} error Error message, if any.
     * @param {module:model/NetworkResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * send custom email
     * @param {module:model/SendCustomEmailRequest} sendCustomEmailRequest 
     * @param {module:api/DefaultApi~sendCustomEmailCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/NetworkResponse}
     */
    sendCustomEmail(sendCustomEmailRequest, callback) {
      let postBody = sendCustomEmailRequest;
      // verify the required parameter 'sendCustomEmailRequest' is set
      if (sendCustomEmailRequest === undefined || sendCustomEmailRequest === null) {
        throw new Error("Missing the required parameter 'sendCustomEmailRequest' when calling sendCustomEmail");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = { 'Authorization': 'Bearer ' + localStorage.getItem('token')
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = NetworkResponse;
      return this.apiClient.callApi(
        '/v0/custom-email', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the sendEmail operation.
     * @callback module:api/DefaultApi~sendEmailCallback
     * @param {String} error Error message, if any.
     * @param {module:model/NetworkResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * send email
     * @param {module:model/SendEmailRequest} sendEmailRequest 
     * @param {module:api/DefaultApi~sendEmailCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/NetworkResponse}
     */
    sendEmail(sendEmailRequest, callback) {
      let postBody = sendEmailRequest;
      // verify the required parameter 'sendEmailRequest' is set
      if (sendEmailRequest === undefined || sendEmailRequest === null) {
        throw new Error("Missing the required parameter 'sendEmailRequest' when calling sendEmail");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = { 'Authorization': 'Bearer ' + localStorage.getItem('token')
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = NetworkResponse;
      return this.apiClient.callApi(
        '/v1/email', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
