import { EnumProduct } from "../enums/EnumProduct"
import { FMF_URLs } from "../router/index.js"
import { getNetworkEnv, env } from "../Globals.js"
import { fmf_error_events } from "./AnalyticsHandler"

import Bugsnag from '@bugsnag/js'
import { getStateMgmtStore } from "../stores/ux_state_store"

export function getRedirectUrl(
    productEnum,
    full_firebase_url,
    anchorText="Preview"
) {
    let redirectUrl = ''

    // full_firebase_url example
    // https://firebasestorage.googleapis.com/v0/b/dev-fillmeforms.appspot.com/o/Preview%2FDemandLetter%2FFillMeForms-DemandLetter-nWbGi-2023-02-14T23-16-47.pdf?alt=media&token=9cf5caec-e4f7-430c-acfc-01080efa8071

    console.log("anchorText = ", anchorText)
    const anchorIndex = full_firebase_url.indexOf(anchorText);

    if (anchorIndex < 1){
        console.error("anchorIndex = ", anchorIndex)
        console.error("FMF_INCIDENT")

        const metadata = {
            anchorText: anchorText,
            anchorIndex: anchorIndex,
        }        
        Bugsnag.leaveBreadcrumb(
            "invalid anchor info",
            metadata
        )
        Bugsnag.notify("invalid anchor")
        //Bugsnag.notify("anchorText=" + anchorText + " anchorIndex="+anchorIndex)

        fmf_error_events(
            "_error",
            "url",
            {
                redirect_url: "invalid anchor",
                anchor_text: anchorText,
                anchor_index: anchorIndex,
            }
        ) 

        return null
    }
    console.log("anchorIndex = ", anchorIndex)
    
    const redirectAnchor = "?result="
    const redirectContent = redirectAnchor + full_firebase_url.substring(anchorIndex, full_firebase_url.length)
    console.log("redirectContent = ", redirectContent)

    let paramDevEnv = ''
    if (getNetworkEnv() != env.PROD){
        // when non-PROD, add one more query param
        paramDevEnv = '&account=399670'
    }


    if (productEnum == EnumProduct.DemandLetter.type){
        redirectUrl = FMF_URLs.DEMAND_LETTER_PAYMENT.url + redirectContent + paramDevEnv

        // set link in state mgmt (for ease of access and for backend)
        getStateMgmtStore().set_product_demand_letter_attribute(
            "preview_and_upgrade_link",
            redirectUrl
        )
        
    } else if (productEnum == EnumProduct.AgreementLetter.type) {
        redirectUrl = FMF_URLs.AGREEMENT_LETTER_PAYMENT.url + redirectContent + paramDevEnv

        // set link in state mgmt (for ease of access and for backend)
        getStateMgmtStore().set_product_agreement_letter_attribute(
            "preview_and_upgrade_link",
            redirectUrl
        )

    } else {
        
        // unknown document 
        const metadata = {
            productEnum: productEnum,
        }
        Bugsnag.leaveBreadcrumb(
            "getRedirectUrl",
            metadata
        )
        Bugsnag.notify("unknown ProductEnum")

        return null
    }

    console.log("")

    console.warn("redirectUrl = ", redirectUrl)
    // https://fillmeforms.com/play?result=Preview%2FDemandLetter%2FFillMeForms-DemandLetter-nWbGi-2023-02-14T23-16-47.pdf?alt=media&token=9cf5caec-e4f7-430c-acfc-01080efa8071
    // https://www.fillmeforms.com/play?result=Preview%2FDemandLetter%2FFillMeForms-DemandLetter-nWbGi-2023-02-14T23-16-47.pdf?alt=media&token=9cf5caec-e4f7-430c-acfc-01080efa8071&account=399670
    return redirectUrl
}