<template>
    <div id="component_journey_card">
        <v-card
            class="mx-auto"
            max-width="400"
            
            >
            <!-- 
                v-bind:loading="is_journey_card_loading"
                v-bind:loading="loading"
                v-bind:style="journey_card_style"
                style="background-color:lightskyblue"
            -->
            
            <template slot="progress">
                <v-progress-linear
                    color="indigo" height="5" indeterminate>
                </v-progress-linear>
            </template>

            <v-img
                class="white--text align-end"
                height="200px"
                v-bind:src="require(`@/assets/${journey_card_cover_photo}`)"
            >
            <!--
                :src="require(`@/assets/img/${filename}`)"
                v-bind:src="journey_card_cover_photo"
                v-bind:src="'/assets/' + journey_card_cover_photo"
                src="/assets/cover_photo_Demand_Letter.png"
                src="@/assets/cover_photo_Demand_Letter.png"
                :src="journey_card_cover_photo"
            -->
                <v-card-title>
                    <div v-html="journey_card_title_html_text" />
                </v-card-title>
                <!-- 
                    <div v-html="journey_card_title_html_text" />
                    {{ journey_card_title_html_text }} 
                -->
            </v-img>
        
            <v-card-subtitle>
                <div v-html="journey_card_subtitle_html_text" />
                <!--
                    <div v-html="journey_card_subtitle_html_text" />
                    {{ journey_card_subtitle_html_text }}
                -->
            </v-card-subtitle>
            
            <!-- class="orange white--text" journey_card_style-->
            <!--mdi-account-box-->
            <v-card-text class="text-right px-5">
                <v-chip  v-bind:style="journey_card_step_status_style" >
                    <v-icon left color="white"> {{ journey_card_step_status_icon }}</v-icon>
                    {{ journey_card_step_status_icon_text }}
                </v-chip>
            </v-card-text>

            <v-card-actions class="align-right">
                <v-spacer></v-spacer>
                <v-btn text @click="onJourneyCardLeftButtonClick()">
                    {{ journey_card_left_button_text }}
                </v-btn>

                <v-btn text @click="onJourneyCardRightButtonClick()"
                        v-show="DISPLAY_journey_card_right_button"
                >
                    {{ journey_card_right_button_text }}
                </v-btn>
            </v-card-actions>
        
        </v-card>
    
    </div>
</template>



<script>

import { EnumJourneyCardStepStatus } from '../../../enums/EnumJourneyCardStepStatus';
import { EnumJourneyCardButtonAction } from '../../../enums/EnumJourneyCardButtonAction';

import { isValidString } from '../../../utils/ValidChecker';

export default {
    name: "component_journey_card",
    //////////////////////////////////////////////////////
    ///////////////// REACTIVE VARIABLES /////////////////
    //////////////////////////////////////////////////////
    data() {
        return {
            CLASS_NAME: "component_journey_card",

            journey_card_cover_photo: "",
            journey_card_title_html_text: "",
            journey_card_subtitle_html_text: "",
            
            journey_card_step_status: "",
            journey_card_step_status_icon: "",
            journey_card_step_status_icon_text: "",
            journey_card_step_status_style: {},

            journey_card_left_button_text: "",
            journey_card_left_button_action: "",
            journey_card_right_button_text: "",
            journey_card_right_button_action: "",
            
            //journey_card_status_icon: 'mdi-account-box',
            //journey_card_status_icon: 'mdi-logout',
            //journey_card_status_icon: 'mdi-watch',
            //journey_card_status_icon: 'mdi-timer-sand',
            //journey_card_status_icon:'mdi-clock-time-two-outline',
            //journey_card_status_icon: 'mdi-clock',
            //journey_card_status_icon: 'mdi-clock-outline',

            //is_journey_card_loading: false,
        }
    },
    props: {
        PROP_view_model: Object,
        PROP_is_card_loading: Boolean,
    },
    watch: {
        PROP_view_model: {
            handler(newVal, oldVal) {
                console.log("PROP_view_model - watcher")
                //console.log("old = ", oldVal)
                //console.log("new = ", newVal)

                /*
                if (newVal["is_focus"]) {                    
                    this.journey_card_style = {
                        backgroundColor: "#16a085"
                        //background-color:lightskyblue
                    }
                } else {
                    this.journey_card_style = {
                        backgroundColor: "#FFF"
                    }
                }
                */
            },
            //deep: true,
            //immediate: true,
        },        
        
    },
    //////////////////////////////////////////////////////
    //////////////// LIFE-CYCLE FUNCTIONS ////////////////
    //////////////////////////////////////////////////////
    created() {
        //console.log(created LIFECYCLE")
        //console.log(this.PROP_view_model)

        this.journey_card_cover_photo = this.PROP_view_model["cover_photo"]
        this.journey_card_title_html_text = this.PROP_view_model["title_html_text"]
        this.journey_card_subtitle_html_text = this.PROP_view_model["subtitle_html_text"]

        this.journey_card_step_status = this.PROP_view_model["step_status"]
        //console.warn("journey_card_step_status = ", this.journey_card_step_status)

        this.journey_card_step_status_icon = this.PROP_view_model["step_status_icon"]
        this.journey_card_step_status_icon_text = this.PROP_view_model["step_status_icon_text"]
        this.journey_card_step_status_style = this.PROP_view_model["journey_card_step_status_style"]

        this.journey_card_left_button_text = this.PROP_view_model["left_button_text"]
        this.journey_card_left_button_action = this.PROP_view_model["left_button_action"]

        this.journey_card_right_button_text = this.PROP_view_model["right_button_text"]
        this.journey_card_right_button_action = this.PROP_view_model["right_button_action"]


        /*
        if (this.journey_card_step_status == EnumJourneyCardStepStatus.COMPLETED.type){
            this.journey_card_step_status_style = {
                background: "#4caf50",
                color: 'white'
            }
            this.journey_card_status_icon = 'mdi-check-circle'
        } else if (this.journey_card_step_status == EnumJourneyCardStepStatus.PENDING.type) {
            this.journey_card_step_status_style = {
                //background: "#FF6d00",
                //background: "#304FFE", // darker blue
                //background: "#03a9f4", // good blue 1
                background: "#2979ff", // good blue 2
                color: 'white'
            }
            this.journey_card_status_icon = 'mdi-clock'
        } else {
            //EnumJourneyCardStepStatus.NEW.type
            //EnumJourneyCardStepStatus.IN_PROGRESS.type
            this.journey_card_step_status_style = {
                background: "#2979ff", // good blue 2
                color: 'white'
            }
            this.journey_card_status_icon = 'mdi-pencil-circle'
        }
        */


    },    

    //////////////////////////////////////////////////////
    ////////////////////// FUNCTIONS /////////////////////
    //////////////////////////////////////////////////////
    methods: {
        populateUI(){

        },
        /*
        onSuccess(result) {
            console.log("onSuccess")
            console.log(result)

            //let authenticateUserResponse = AuthenticateUserResponse.constructFromObject(result);
            //console.log(authenticateUserResponse)

            console.log("code continues here")
        },
        onError(error) {
            console.log("onError")
            console.log(error)

            console.log("code can still continue here")
        },
        */
        onJourneyCardLeftButtonClick() {
            console.log("onJourneyCardLeftButtonClick()")

            /*
            this.is_journey_card_loading = true
            setTimeout(() => (this.is_journey_card_loading = false), 2000)
            */

            this.$emit(
                "onProceed",
                this.journey_card_left_button_action,
                //EnumJourneyCardButtonAction.DemandLetter.UPGRADE,
            )

        },
        onJourneyCardRightButtonClick() {
            console.log("onJourneyCardRightButtonClick()")

            this.$emit(
                "onProceed",
                this.journey_card_right_button_action
            )

        },
    },
    computed: {
        DISPLAY_journey_card_right_button() {
            const valid_text = isValidString(this.journey_card_right_button_text)
            const valid_action = isValidString(this.journey_card_right_button_action)
            return valid_text && valid_action
        },

        /*
        is_journey_card_loading(){
            //console.log("is_journey_card_loading()")
            //console.log("PROP_is_card_loading = ", this.PROP_is_card_loading)
            return this.PROP_is_card_loading
        }
        */

    }    

};
</script>