
export function getIndigoText(
    stringInput
) {
    let htmlOutput
    //'<a class="indigo--text">' + recommended_product + '</a>'
    htmlOutput = '<span class="indigo--text">' + stringInput + '</span>'

    return htmlOutput
}


export function getIndigoBoldText(
    stringInput
) {
    let htmlOutput
    //'<a class="indigo--text">' + recommended_product + '</a>'
    htmlOutput = '<span class="indigo--text text--darken-2">' + stringInput + '</span>'

    return getBoldText(htmlOutput)
}


export function getRedText(
    stringInput
) {
    let htmlOutput
    // class="text-center pink--text text--darken-2 mx-5 mt-15"
    htmlOutput = '<span class="pink--text">' + stringInput + '</span>'

    return htmlOutput
}

export function getRedDarkenText(
    stringInput
) {
    let htmlOutput
    // class="text-center pink--text text--darken-2 mx-5 mt-15"
    htmlOutput = '<span class="pink--text text--darken-2">' + stringInput + '</span>'

    return htmlOutput
}

export function getBoldText(
    stringInput
) {
    let htmlOutput

    htmlOutput = '<strong>' + stringInput + '</strong>'

    return htmlOutput
}


export function getItalicizedText(
    stringInput
) {
    let htmlOutput

    htmlOutput = '<i>' + stringInput + '</i>'

    return htmlOutput
}

export function getSmallText(
    stringInput
) {
    let htmlOutput

    htmlOutput = '<small>' + stringInput + '</small>'

    return htmlOutput
}


export function getHyperlinkNewTabText(
    stringInput, url, textColor
) {
    let htmlOutput
    //<a href="https://www.fillmeforms.com/private-beta" target="_blank">Click here</a> to join
    //htmlOutput = '<a href="' + url + '"' + ' target="_blank">' + stringInput + '</a>'

    //<a class="white--text" href="/terms">Terms of Service</a>
    // target="_blank"
    htmlOutput = '<a class='
                + '"'
                    + textColor + "--text"
                + '"'
                + ' href='
                + '"'
                    + url
                + '"'
                + ' target='
                + '"'
                    + "_blank"
                + '"'                
                + '>'
                    + stringInput
                + '</a>'

    return htmlOutput
}

export function getHyperlinkText(
    stringInput, url, textColor
) {
    let htmlOutput
    //<a href="https://www.fillmeforms.com/private-beta" target="_blank">Click here</a> to join
    //<a class="white--text" href="/terms">Terms of Service</a>
    //htmlOutput = '<a href="' + url + '"' + '>' + stringInput + '</a>'

    htmlOutput = '<a class='
                + '"'
                    + textColor + "--text"
                + '"'
                + ' href='
                + '"'
                    + url
                + '"'
                //+ ' target='
                //+ '"'
                //    + "_blank"
                //+ '"'                
                + '>'
                    + stringInput
                + '</a>'    

    return htmlOutput
}

export function getWhiteText(
    stringInput
) {
    let htmlOutput
    //'<a class="indigo--text">' + recommended_product + '</a>'
    htmlOutput = '<span class="white--text">' + stringInput + '</span>'

    return htmlOutput
}


export function getEmailSupportText(
    stringInput,
    recommended_product
) {
    let htmlOutput
    let emailSubject = "Question about " + recommended_product
    
    htmlOutput = '<a href="mailto:contact@fillmeforms.com?subject=' + emailSubject + '">' + stringInput + '</a>'

    return htmlOutput
}