/**
 * FillMeForms API
 * FillMeForms API Specs Used for client-server communication 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The SendEmailRequest model module.
 * @module model/SendEmailRequest
 * @version 0.0.1
 */
class SendEmailRequest {
    /**
     * Constructs a new <code>SendEmailRequest</code>.
     * tell the server what email to send
     * @alias module:model/SendEmailRequest
     * @param messageType {module:model/SendEmailRequest.MessageTypeEnum} the type of email message to be sent
     */
    constructor(messageType) { 
        
        SendEmailRequest.initialize(this, messageType);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, messageType) { 
        obj['message_type'] = messageType;
    }

    /**
     * Constructs a <code>SendEmailRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SendEmailRequest} obj Optional instance to populate.
     * @return {module:model/SendEmailRequest} The populated <code>SendEmailRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SendEmailRequest();

            if (data.hasOwnProperty('message_type')) {
                obj['message_type'] = ApiClient.convertToType(data['message_type'], 'String');
            }
        }
        return obj;
    }


}

/**
 * the type of email message to be sent
 * @member {module:model/SendEmailRequest.MessageTypeEnum} message_type
 */
SendEmailRequest.prototype['message_type'] = undefined;





/**
 * Allowed values for the <code>message_type</code> property.
 * @enum {String}
 * @readonly
 */
SendEmailRequest['MessageTypeEnum'] = {

    /**
     * value: "COMPLETED_SIGN_UP"
     * @const
     */
    "COMPLETED_SIGN_UP": "COMPLETED_SIGN_UP",

    /**
     * value: "GENERATED_DEMAND_LETTER"
     * @const
     */
    "GENERATED_DEMAND_LETTER": "GENERATED_DEMAND_LETTER",

    /**
     * value: "PAID_DEMAND_LETTER"
     * @const
     */
    "PAID_DEMAND_LETTER": "PAID_DEMAND_LETTER",

    /**
     * value: "SIGNED_DEMAND_LETTER"
     * @const
     */
    "SIGNED_DEMAND_LETTER": "SIGNED_DEMAND_LETTER",

    /**
     * value: "MAILED_DEMAND_LETTER"
     * @const
     */
    "MAILED_DEMAND_LETTER": "MAILED_DEMAND_LETTER",

    /**
     * value: "GENERATED_AGREEMENT_LETTER"
     * @const
     */
    "GENERATED_AGREEMENT_LETTER": "GENERATED_AGREEMENT_LETTER",

    /**
     * value: "PAID_AGREEMENT_LETTER"
     * @const
     */
    "PAID_AGREEMENT_LETTER": "PAID_AGREEMENT_LETTER",

    /**
     * value: "SIGNED_AGREEMENT_LETTER"
     * @const
     */
    "SIGNED_AGREEMENT_LETTER": "SIGNED_AGREEMENT_LETTER"
};



export default SendEmailRequest;

