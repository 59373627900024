/**
 * FillMeForms API
 * FillMeForms API Specs Used for client-server communication 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The SendCustomEmailRequest model module.
 * @module model/SendCustomEmailRequest
 * @version 0.0.1
 */
class SendCustomEmailRequest {
    /**
     * Constructs a new <code>SendCustomEmailRequest</code>.
     * tell the server what email to send
     * @alias module:model/SendCustomEmailRequest
     * @param email {String} destination email address
     * @param special {Number} special code
     * @param messageType {module:model/SendCustomEmailRequest.MessageTypeEnum} the type of email message to be sent
     */
    constructor(email, special, messageType) { 
        
        SendCustomEmailRequest.initialize(this, email, special, messageType);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, email, special, messageType) { 
        obj['email'] = email;
        obj['special'] = special;
        obj['message_type'] = messageType;
    }

    /**
     * Constructs a <code>SendCustomEmailRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SendCustomEmailRequest} obj Optional instance to populate.
     * @return {module:model/SendCustomEmailRequest} The populated <code>SendCustomEmailRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SendCustomEmailRequest();

            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('special')) {
                obj['special'] = ApiClient.convertToType(data['special'], 'Number');
            }
            if (data.hasOwnProperty('tracking_link')) {
                obj['tracking_link'] = ApiClient.convertToType(data['tracking_link'], 'String');
            }
            if (data.hasOwnProperty('message_type')) {
                obj['message_type'] = ApiClient.convertToType(data['message_type'], 'String');
            }
        }
        return obj;
    }


}

/**
 * destination email address
 * @member {String} email
 */
SendCustomEmailRequest.prototype['email'] = undefined;

/**
 * special code
 * @member {Number} special
 */
SendCustomEmailRequest.prototype['special'] = undefined;

/**
 * tracking link for mailed service only
 * @member {String} tracking_link
 */
SendCustomEmailRequest.prototype['tracking_link'] = undefined;

/**
 * the type of email message to be sent
 * @member {module:model/SendCustomEmailRequest.MessageTypeEnum} message_type
 */
SendCustomEmailRequest.prototype['message_type'] = undefined;





/**
 * Allowed values for the <code>message_type</code> property.
 * @enum {String}
 * @readonly
 */
SendCustomEmailRequest['MessageTypeEnum'] = {

    /**
     * value: "PAID_DEMAND_LETTER"
     * @const
     */
    "PAID_DEMAND_LETTER": "PAID_DEMAND_LETTER",

    /**
     * value: "PAID_AGREEMENT_LETTER"
     * @const
     */
    "PAID_AGREEMENT_LETTER": "PAID_AGREEMENT_LETTER",

    /**
     * value: "SIGNED_DEMAND_LETTER"
     * @const
     */
    "SIGNED_DEMAND_LETTER": "SIGNED_DEMAND_LETTER",

    /**
     * value: "MAILED_DEMAND_LETTER"
     * @const
     */
    "MAILED_DEMAND_LETTER": "MAILED_DEMAND_LETTER",

    /**
     * value: "SIGNED_AGREEMENT_LETTER"
     * @const
     */
    "SIGNED_AGREEMENT_LETTER": "SIGNED_AGREEMENT_LETTER"
};



export default SendCustomEmailRequest;

