// ENUM class

export const EnumJourneyCardButtonAction = Object.freeze({

    DemandLetter: { 
        CONTINUE: "continue",
        UPGRADE: "upgrade",
        VIEW: "view",
        TRACK: "track",
    },
    AgreementLetter: {
        CONTINUE: "continue",
        UPGRADE: "upgrade",
        VIEW: "view",
        TRACK: "track",
    },
    CourtPapers: {
        CONTINUE: "continue",
    },    
    CasePacket: {
        CONTINUE: "continue",     
    },

});