
export function getSmallClaimsSpecByState(state){
    
    let smallClaimsStatesArray = getSmallClaimsStatesArray();

    for (let i = 0; i < smallClaimsStatesArray.length; i++) {

        if (smallClaimsStatesArray[i].state == state){
            return smallClaimsStatesArray[i]
        }

    }
    return null;

}

function getSmallClaimsStatesArray(){
    return [
        {
            "state": "Alabama",
            "form_id": 101,
            "spec": {
                "general_amt_limit": 6000,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Alaska",
            "form_id": 102,
            "spec": {
                "general_amt_limit": 10000,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Arizona",
            "form_id": 103,
            "spec": {
                "general_amt_limit": 3500,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Arkansas",
            "form_id": 104,
            "spec": {
                "general_amt_limit": 5000,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "California",
            "form_id": 105,
            "spec": {
                "general_amt_limit": 10000,
                "special_conditions": true,
                "special_amt_limit": 5000,
                "calendar_case_max": 2
            }
        },

        {
            "state": "Colorado",
            "form_id": 106,
            "spec": {
                "general_amt_limit": 7500,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Connecticut",
            "form_id": 107,
            "spec": {
                "general_amt_limit": 5000,
                "special_conditions": true,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Delaware",
            "form_id": 108,
            "spec": {
                "general_amt_limit": 25000,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "District of Columbia",
            "form_id": 109,
            "spec": {
                "general_amt_limit": 10000,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Florida",
            "form_id": 110,
            "spec": {
                "general_amt_limit": 8000,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Georgia",
            "form_id": 111,
            "spec": {
                "general_amt_limit": 15000,
                "special_conditions": true,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Hawaii",
            "form_id": 112,
            "spec": {
                "general_amt_limit": 5000,
                "special_conditions": true,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Idaho",
            "form_id": 113,
            "spec": {
                "general_amt_limit": 5000,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Illinois",
            "form_id": 114,
            "spec": {
                "general_amt_limit": 10000,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Indiana",
            "form_id": 115,
            "spec": {
                "general_amt_limit": 10000,
                "special_conditions": true,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Iowa",
            "form_id": 116,
            "spec": {
                "general_amt_limit": 6500,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Kansas",
            "form_id": 117,
            "spec": {
                "general_amt_limit": 4000,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Kentucky",
            "form_id": 118,
            "spec": {
                "general_amt_limit": 2500,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Louisiana",
            "form_id": 119,
            "spec": {
                "general_amt_limit": 5000,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Maine",
            "form_id": 120,
            "spec": {
                "general_amt_limit": 6000,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Maryland",
            "form_id": 121,
            "spec": {
                "general_amt_limit": 5000,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Massachusetts",
            "form_id": 122,
            "spec": {
                "general_amt_limit": 7000,
                "special_conditions": true,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Michigan",
            "form_id": 123,
            "spec": {
                "general_amt_limit": 6500,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Minnesota",
            "form_id": 124,
            "spec": {
                "general_amt_limit": 15000,
                "special_conditions": true,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Mississippi",
            "form_id": 125,
            "spec": {
                "general_amt_limit": 3500,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Missouri",
            "form_id": 126,
            "spec": {
                "general_amt_limit": 5000,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Montana",
            "form_id": 127,
            "spec": {
                "general_amt_limit": 6000,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Nebraska",
            "form_id": 128,
            "spec": {
                "general_amt_limit": 3900,
                "special_conditions": true,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Nevada",
            "form_id": 129,
            "spec": {
                "general_amt_limit": 10000,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "New Hampshire",
            "form_id": 130,
            "spec": {
                "general_amt_limit": 10000,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "New Jersey",
            "form_id": 131,
            "spec": {
                "general_amt_limit": 3000,
                "special_conditions": true,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "New Mexico",
            "form_id": 132,
            "spec": {
                "general_amt_limit": 10000,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "New York",
            "form_id": 133,
            "spec": {
                "general_amt_limit": 10000,
                "special_conditions": true,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "North Carolina",
            "form_id": 134,
            "spec": {
                "general_amt_limit": 10000,
                "special_conditions": true,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "North Dakota",
            "form_id": 135,
            "spec": {
                "general_amt_limit": 15000,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Ohio",
            "form_id": 136,
            "spec": {
                "general_amt_limit": 6000,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Oklahoma",
            "form_id": 137,
            "spec": {
                "general_amt_limit": 10000,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Oregon",
            "form_id": 138,
            "spec": {
                "general_amt_limit": 10000,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Pennsylvania",
            "form_id": 139,
            "spec": {
                "general_amt_limit": 12000,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Rhode Island",
            "form_id": 140,
            "spec": {
                "general_amt_limit": 2500,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "South Carolina",
            "form_id": 141,
            "spec": {
                "general_amt_limit": 7500,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "South Dakota",
            "form_id": 142,
            "spec": {
                "general_amt_limit": 12000,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Tennessee",
            "form_id": 143,
            "spec": {
                "general_amt_limit": 25000,
                "special_conditions": true,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Texas",
            "form_id": 144,
            "spec": {
                "general_amt_limit": 20000,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Utah",
            "form_id": 145,
            "spec": {
                "general_amt_limit": 11000,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Vermont",
            "form_id": 146,
            "spec": {
                "general_amt_limit": 5000,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Virginia",
            "form_id": 147,
            "spec": {
                "general_amt_limit": 5000,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Washington",
            "form_id": 148,
            "spec": {
                "general_amt_limit": 10000,
                "special_conditions": true,
                "special_amt_limit": 5000,
                "calendar_case_max": -1
            }
        },

        {
            "state": "West Virginia",
            "form_id": 149,
            "spec": {
                "general_amt_limit": 10000,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Wisconsin",
            "form_id": 150,
            "spec": {
                "general_amt_limit": 10000,
                "special_conditions": true,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        },

        {
            "state": "Wyoming",
            "form_id": 151,
            "spec": {
                "general_amt_limit": 6000,
                "special_conditions": false,
                "special_amt_limit": -1,
                "calendar_case_max": -1
            }
        }

    ]
}
