/**
 * FillMeForms API
 * FillMeForms API Specs Used for client-server communication 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The CheckAuthStatusRequest model module.
 * @module model/CheckAuthStatusRequest
 * @version 0.0.1
 */
class CheckAuthStatusRequest {
    /**
     * Constructs a new <code>CheckAuthStatusRequest</code>.
     * pass the (firebase) token from client to server
     * @alias module:model/CheckAuthStatusRequest
     * @param token {String} firebase token
     */
    constructor(token) { 
        
        CheckAuthStatusRequest.initialize(this, token);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, token) { 
        obj['token'] = token;
    }

    /**
     * Constructs a <code>CheckAuthStatusRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CheckAuthStatusRequest} obj Optional instance to populate.
     * @return {module:model/CheckAuthStatusRequest} The populated <code>CheckAuthStatusRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CheckAuthStatusRequest();

            if (data.hasOwnProperty('token')) {
                obj['token'] = ApiClient.convertToType(data['token'], 'String');
            }
        }
        return obj;
    }


}

/**
 * firebase token
 * @member {String} token
 */
CheckAuthStatusRequest.prototype['token'] = undefined;






export default CheckAuthStatusRequest;

