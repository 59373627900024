import { ref, set } from "firebase/database";
import { update } from "firebase/database";
import { firebaseDatabase_events } from '../main.js';
import { now_in_iso } from '../utils/DateFormatHandler.js'
import { getStateMgmtStore } from "../stores/ux_state_store.js";


export function fmf_error_events(
    event_name,             // static: "_error"
    error_type = "default", // string options: api, firebase, ui
    event_params            // optional
) {

    const directory = event_name + '/' + error_type + '/' + now_in_iso()   // 2023-01-26T05:41:09

    const databaseReference = ref(
        firebaseDatabase_events,
        directory
    )

    // update data in firebase database
    let dict = {}

    let user_identifier
    if (getStateMgmtStore().get_is_user_logged_in) {
        user_identifier = getStateMgmtStore().get_auth_user_id
        dict["_user_id"] = user_identifier
        dict["_email"] = getStateMgmtStore().get_auth_user_email
    } else {
        user_identifier = getStateMgmtStore().get_session
        dict["_visitor"] = user_identifier
    }

    //console.log("event_params = ", event_params)
    //console.log("dict = ", dict)
    const event_attributes = { ...event_params, ...dict }
    //console.log("event_attributes = ", event_attributes)

    update(databaseReference, event_attributes);
}

export function fmf_event(event_name, event_params){

    const directory = event_name + '/' + now_in_iso()   // 2023-01-26T05:41:09

    const databaseReference = ref(
        firebaseDatabase_events,
        directory
    )

    // update data in firebase database
    let dict = {}

    let user_identifier
    if (getStateMgmtStore().get_is_user_logged_in) {
        user_identifier = getStateMgmtStore().get_auth_user_id
        dict["_user_id"] = user_identifier
        dict["_email"] = getStateMgmtStore().get_auth_user_email
    } else {
        user_identifier = getStateMgmtStore().get_session
        dict["_visitor"] = user_identifier
    }

    //console.log("event_params = ", event_params)
    //console.log("dict = ", dict)
    const event_attributes = { ...event_params, ...dict }
    //console.log("event_attributes = ", event_attributes)

    
    // method below OVERWRITES
    /*
    set(
        ref(
            databaseReference,
            //event_name + '/' + (new Date).toISOString().substring(0, 19)
            directory
        ),
        event_attributes
    )
    */
    update(databaseReference, event_attributes);

}


export function analyticsEventLog(
    databaseReference,
    eventName,
    eventAttributes
) {
    /*
    console.log("analyticsEventLog()")
    console.log(databaseReference)
    console.log(eventName)
    console.log(eventName + '/' + (new Date).toISOString().substring(0, 19))
    console.log(eventAttributes)
    */

    // let y = (new Date).toISOString().substring(0, 19)
    // console.log("y = ", y)
    // example: 2022-11-01T04:49:43
    // from:
    // https://stackoverflow.com/questions/49984952/javascript-shorter-way-to-format-date
    // https://stackoverflow.com/questions/30888197/format-datetime-to-yyyy-mm-dd-hhmmss-in-moment-js


    // log to Firebase RealTime Database
    set(
        ref(
            databaseReference,
            eventName + '/' + (new Date).toISOString().substring(0, 19)
        ),
        eventAttributes
    )

    /*
    // EXAMPLE BELOW with direct values
    set(
        ref(firebaseDatabase, 'API_ERROR/' + new Date().toGMTString()),
        {
            param: "CreateFormInstance",
            message: "response json is null"
        }
    );
    */
}