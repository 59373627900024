/* THIS CLASS IS EDITED */
/* THIS CLASS IS EDITED */
/* THIS CLASS IS EDITED */

/**
 * FillMeForms API
 * FillMeForms API Specs Used for client-server communication 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The NetworkErrorResponse model module.
 * @module model/NetworkErrorResponse
 * @version 0.0.1
 */
class NetworkErrorResponse {
    /**
     * Constructs a new <code>NetworkErrorResponse</code>.
     * wrapper for non-2xx http networks calls
     * @alias module:model/NetworkErrorResponse
     * @param status {String} network call's http result status.
     * @param code {Number} network call's http result status code.
     * @param message {String} Typically a description of what went wrong. If applicable, state how to resolve.
     */
    constructor(status, code, message) {

        NetworkErrorResponse.initialize(this, status, code, message);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, status, code, message) {
        obj['status'] = status;
        obj['code'] = code;
        obj['message'] = message;
    }

    /**
     * Constructs a <code>NetworkErrorResponse</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/NetworkErrorResponse} obj Optional instance to populate.
     * @return {module:model/NetworkErrorResponse} The populated <code>NetworkErrorResponse</code> instance.
     */
    static constructFromObject(data, obj) {
        ////////////////////////////////////////
        // MANUAL EDITED
        data = JSON.parse(data);
        ////////////////////////////////////////
        if (data) {
            obj = obj || new NetworkErrorResponse();

            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
            if (data.hasOwnProperty('code')) {
                obj['code'] = ApiClient.convertToType(data['code'], 'Number');
            }
            if (data.hasOwnProperty('message')) {
                obj['message'] = ApiClient.convertToType(data['message'], 'String');
            }
            if (data.hasOwnProperty('data')) {
                obj['data'] = ApiClient.convertToType(data['data'], Object);
            }
        }
        return obj;
    }


}

/**
 * network call's http result status.
 * @member {String} status
 */
NetworkErrorResponse.prototype['status'] = undefined;

/**
 * network call's http result status code.
 * @member {Number} code
 */
NetworkErrorResponse.prototype['code'] = undefined;

/**
 * Typically a description of what went wrong. If applicable, state how to resolve.
 * @member {String} message
 */
NetworkErrorResponse.prototype['message'] = undefined;

/**
 * An optional response object, if applicable.
 * @member {Object} data
 */
NetworkErrorResponse.prototype['data'] = undefined;






export default NetworkErrorResponse;
