////////////////////////////////////////////////////////
//// This file is where the Vue Instance is created ////
////////////////////////////////////////////////////////

// Vue APIs
// https://vuejs.org/api/application.html

// overall Vue framework setup
//https://www.freecodecamp.org/news/a-quick-introduction-to-vue-js-72937ee8880d/

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueMeta from 'vue-meta'

import { getStateMgmtStore } from './stores/ux_state_store.js'
// import fmfPiniaInstance from "./stores/ux_state_store.js";
// ^ this is another way to setup ^


import { createPinia} from 'pinia'
import { PiniaVuePlugin } from 'pinia' // need this for Vue2
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAnalytics } from "firebase/analytics";

import { getNetworkEnv, env } from './Globals'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

// >>FLOW OF CODE LIFECYCLE<<
// This line follows from end of App.vue
//console.log("main.js 0")

////////////////////////////
//// IMPORTS COMPLETED ////
//// CODE STARTS BELOW ////
////////////////////////////

// first action is to define current environment
const currentEnv = getNetworkEnv()
//console.log("currentEnv = ", currentEnv)

const build_package = require('../package.json');
/*
console.log(build_package);
console.log(typeof build_package);
console.log(build_package.version);
console.log(typeof build_package.version);
*/

const bugsnagApiKey = currentEnv == env.PROD ? '4b8379191e34d520e959250c9d34fa50' : '867515ec0c063d3aa359b76944544ef3'
const bugsnagInstanceName = currentEnv == env.PROD ? 'production' : 'develop'

// init BugSnag
// https://docs.bugsnag.com/platforms/javascript/vue
// https://docs.bugsnag.com/platforms/javascript/vue/configuration-options/
Bugsnag.start({
  apiKey: bugsnagApiKey,
  plugins: [new BugsnagPluginVue()],
  releaseStage: bugsnagInstanceName,
  appVersion: build_package.version,
  //enabledBreadcrumbTypes: ['error', 'log', 'navigation', 'request', 'user'],
  enabledErrorTypes: {
    unhandledExceptions: true,
    unhandledRejections: true
  }
})

const bugsnagVue = Bugsnag.getPlugin('vue')
bugsnagVue.installVueErrorHandler(Vue)

console.log("BugSnag Setup Completed")

////////////////////////////////////////////////


let firebaseConfig_root;
let eventsDatabaseURL;
let uxDatabaseURL;
let visitorsDatabaseURL;

if (currentEnv == env.PROD) {
  firebaseConfig_root = {
    apiKey: "AIzaSyD3JIH-xWYHNJMPyI7sArwFbDXkskEMnww",
    authDomain: "prod-fillmeforms.firebaseapp.com",
    projectId: "prod-fillmeforms",
    storageBucket: "prod-fillmeforms.appspot.com",
    messagingSenderId: "216999242946",
    appId: "1:216999242946:web:5dbf00a386c30834731f5b",
    measurementId: "G-ZF23H0NDBK",
    //databaseURL: ...
  };

  eventsDatabaseURL = "https://prod-fillmeforms-events.firebaseio.com/"
  uxDatabaseURL = "https://prod-fillmeforms-ux.firebaseio.com/"
  visitorsDatabaseURL = "https://prod-fillmeforms-visitors.firebaseio.com/"

} else {
  firebaseConfig_root = {
    apiKey: "AIzaSyDqzp8_7Pn2tYYjA6wMCTKqBlMOtTDCuiU",
    authDomain: "dev-fillmeforms.firebaseapp.com",
    projectId: "dev-fillmeforms",
    storageBucket: "dev-fillmeforms.appspot.com",
    messagingSenderId: "224342942174",
    appId: "1:224342942174:web:9207043b4185b9844f3d4f",
    measurementId: "G-KG3BN8CCZT",
    //databaseURL: ...
  };

  eventsDatabaseURL = "https://dev-fillmeforms-events.firebaseio.com/"
  uxDatabaseURL = "https://dev-fillmeforms-ux.firebaseio.com/"
  visitorsDatabaseURL = "https://dev-fillmeforms-visitors.firebaseio.com/"
}


// create new firebaseConfigs from root object (firebaseConfig_root)
// the clone operation is '...'
let firebaseConfig_eventsDatabase = { ...firebaseConfig_root };
let firebaseConfig_uxDatabase = { ...firebaseConfig_root };
let firebaseConfig_visitorsDatabase = { ...firebaseConfig_root };

// append databaseURL to firebase configs based
firebaseConfig_eventsDatabase["databaseURL"] = eventsDatabaseURL
firebaseConfig_uxDatabase["databaseURL"] = uxDatabaseURL
firebaseConfig_visitorsDatabase["databaseURL"] = visitorsDatabaseURL

const firebaseApp_eventsDatabase = initializeApp(firebaseConfig_eventsDatabase);
const firebaseApp_uxDatabase = initializeApp(firebaseConfig_uxDatabase, 'ux');
const firebaseApp_visitorsDatabase = initializeApp(firebaseConfig_uxDatabase, 'visitors');
export const firebaseApp = firebaseApp_uxDatabase

export const firebaseDatabase_events = getDatabase(firebaseApp_eventsDatabase, eventsDatabaseURL);
export const firebaseDatabase_ux = getDatabase(firebaseApp_uxDatabase, uxDatabaseURL);
export const firebaseDatabase_visitors = getDatabase(firebaseApp_visitorsDatabase, visitorsDatabaseURL);
export const firebaseAuthInstance = getAuth(firebaseApp_uxDatabase)

const googleAnalytics = getAnalytics(firebaseApp_eventsDatabase);

console.log("Firebase Setup Completed")

// how to create and pass values to the rest of the Vue app
// https://stackoverflow.com/questions/52944052/creating-a-single-instance-of-a-class-within-a-vue-application
// Vue2: use .prototype https://v2.vuejs.org/v2/cookbook/adding-instance-properties.html
// Vue3: use .globalproperties https://vuejs.org/api/application.html#app-config-globalproperties
Vue.prototype.$firebaseDatabase_events = firebaseDatabase_events;
Vue.prototype.$firebaseDatabase_ux = firebaseDatabase_ux;
Vue.prototype.$firebaseDatabase_visitors = firebaseDatabase_visitors;
Vue.prototype.$firebaseApp = firebaseApp_uxDatabase; // don't need this, use $firebaseAuthInstance
Vue.prototype.$firebaseAuthInstance = firebaseAuthInstance
Vue.prototype.$googleAnalytics = googleAnalytics;
//Vue.prototype.$googleAnalyticsInstance = googleAnalytics; // rename later
Vue.prototype.$currentEnv = currentEnv;

console.log("Vue.prototype Setup Completed")

Vue.config.productionTip = false;

// apply plugins with .use()
Vue.use(vuetify);
Vue.use(VueMeta);
Vue.use(PiniaVuePlugin) // need this for Vue2

console.log("Vue.use() completed")

// setup state mgmt store
const fmfPiniaInstance = createPinia();
fmfPiniaInstance.use(piniaPluginPersistedstate)
Vue.prototype.$piniaInstance = fmfPiniaInstance;
// https://pinia.vuejs.org/getting-started.html#installation
// https://pinia.vuejs.org/core-concepts/outside-component-usage.html#single-page-applications
getStateMgmtStore(fmfPiniaInstance);

console.log("Vue StateMgmt Setup Completed")

// new Vue() creates a new Vue Instance and mount imports
// into the "app" div section of public/index.html
//console.log("main.js - before new Vue()")
// >>FLOW OF CODE LIFECYCLE<<
// insider new Vue() are lifecycle functions in App.vue

const fmfVueInstance = new Vue(
  {
    router,
    vuetify,
    fmfPiniaInstance,
    render: h => h(App)
  }
)
fmfVueInstance.$mount('#app')
//console.log("main.js - after new Vue()")
// >>FLOW OF CODE LIFECYCLE<<
// App.vue loaded with elements created and mounted
