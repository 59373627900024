import { defineStore } from "pinia";
/*
// V this is another way to setup V
// https://stackoverflow.com/questions/70710965/vue-cant-access-pinia-store-in-beforeenter-vue-router

import { createPinia } from "pinia";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

const fmfPiniaInstance = createPinia();
fmfPiniaInstance.use(piniaPluginPersistedstate)
export default fmfPiniaInstance;
*/

import { getSmallClaimsSpecByState } from '../enums/EnumSmallClaimsSpec.js'

import { isValidString } from '../utils/ValidChecker.js'
import { now_in_iso, now_in_unix, now_in_pacific_locale } from '../utils/DateFormatHandler.js'

import { firebaseDatabase_ux } from '../main.js';
import { firebaseDatabase_visitors } from '../main.js';
//import { firebaseAuthInstance } from '../main.js';
import { ref, update } from 'firebase/database';
import { EnumPartyType } from "../enums/EnumPartyType.js";

export const getStateMgmtStore = defineStore(    
    "ux_state_store", // state mgmt store id
    {
        state: () => ({

            session: null,

            auth: {
                token: null,
                user_email: null,
                user_id: null,
                user_display_name: null,
                //generated_at_unix: null,
            },

            product_start:{
                recommended_product: null, // EnumProduct
                has_filed_court_case: null, // boolean
                party_type: null, // plaintiff, defendant
                //sent_welcome_email: false, // boolean
                //is_restart_flow: false, // is pressed "changed" in Journey page

            },

            current_demand_letter_index: 0,
            // could be in another store
            demand_letters:[
                //{...},
            ],

            product_demand_letter: {
                has_started: false, // flipped on 1st question in product flow

                plaintiff_type: null, // business, individual
                defendant_type: null, // business, individual
                sue_in_state: null,
                demand_amount: 0,     // Number

                defendant_contact_person_name: null,
                defendant_business_entity: null, // only for defendant is business
                defendant_address: null,
                defendant_city: null,
                defendant_state: null,
                defendant_zipcode: null,

                plaintiff_contact_person_name: null,
                plaintiff_business_entity: null, // only for defendant is business
                plaintiff_business_job_title: null, // only for plaintiff is business
                plaintiff_phone_number: null,
                plaintiff_address: null,
                plaintiff_city: null,
                plaintiff_state: null,
                plaintiff_zipcode: null,

                demand_reason_type: null,
                demand_reason: null,
                rented_date: null,
                rented_address: null,
                bad_check_date: null,
                bad_check_reason: null,
                stop_payment_date: null,

                last_updated_utc_iso: null, // string: 2023-01-26T05:41:09

                preview_document_name: null,
                preview_storage_path: null,
                preview_generated_utc_iso: null,
                preview_expiration_minutes: null,

                preview_and_upgrade_link: null,     // https://fillmeforms.com/play?result=Preview%2FDemandLetter%2FFillMeForms-DemandLetter-nWbGi-2023-02-14T23-16-47.pdf?alt=media&token=9cf5caec-e4f7-430c-acfc-01080efa8071

                paid_document_name: null,
                paid_storage_path: null,
                
                paid_file_generated_utc_iso: null,
                paid_file_expiration_minutes: null,

                paid_receipt_id: null,                  // manually update, will inject from SQUARE
                paid_timestamp_utc_iso: null,           // inject from SQUARE redirect

                paid_mail_tracking_link: null,          // manually update once mailed at post office

                //last_updated: "2023-01-26",
                //document_name: "STATE Small Claims",
                //free_url: "https://math.berkeley.edu/~ribet/55/mt1_questions_only.pdf",

            },



            current_agreement_letter_index: 0,
            // could be in another store
            agreement_letters: [
                //{...},
            ],
            product_agreement_letter: {
                has_started: false, // flipped on 1st question in product flow

                has_small_claims_case: null,        // boolean
                small_claims_settlement_type: null, // Plaintiff, Defendant, Both
                small_claims_case_detail_case_number: null,  // string
                small_claims_case_detail_courthouse_address: null,  // string

                respondent_type: null, // Individual, Business
                respondent_name: null, // String
                respondent_address: null, // String
                respondent_phone: null, // String
                respondent_business_entity: null, // String (Business only)

                claimant_type: null, // Individual, Business
                claimant_name: null, // String
                claimant_address: null, // String
                claimant_phone: null, // String
                claimant_business_entity: null, // String (Business only)

                settlement_amount: 0, // Number

                settlement_payment_preference: null, // EnumPaymentPreference: OneTime, Installment
                settlement_payment_payable_to: null, // 
                settlement_payment_pay_by_method: null, // Cash, Check, E-Transfer, etc
                settlement_payment_pay_to_address: null, // only for cash & check
                settlement_payment_pay_date: null, // String (YYYY-MM-DD)
                settlement_payment_installment_frequency: null, // only for Installment
                settlement_payment_installment_amount: null, // only for Installment, to be filled as number

                settlement_effective_date: null, // String (YYYY-MM-DD)

                //
                //
                //

                last_updated_utc_iso: null, // string: 2023-01-26T05:41:09

                preview_document_name: null,
                preview_storage_path: null,
                preview_generated_utc_iso: null,
                preview_expiration_minutes: null,

                preview_and_upgrade_link: null,     // https://fillmeforms.com/play?result=Preview%2FDemandLetter%2FFillMeForms-DemandLetter-nWbGi-2023-02-14T23-16-47.pdf?alt=media&token=9cf5caec-e4f7-430c-acfc-01080efa8071

                paid_document_name: null,
                paid_storage_path: null,

                paid_file_generated_utc_iso: null,
                paid_file_expiration_minutes: null,

                paid_receipt_id: null,                  // manually update, will inject from SQUARE
                paid_timestamp_utc_iso: null,           // inject from SQUARE redirect
            },

            journey_progress: {
                selected_product: null, // EnumProduct
            },

            selected_state_small_claims_spec: null,

            /*
            // TO REMOVE LATER
            count: 0,
            rawItems: ["one", "two", "i", "j", "k"],
            */
        }),

        getters: {
            get_session(state) {
                if(this.session == null){
                    console.warn("session is null in getter")
                }
                return state.session
            },
            get_product_start(state) {
                return state.product_start
            },
            // note: not used
            get_is_product_start_party_type_plaintiff(state) {
                return state.product_start.party_type == EnumPartyType.Plaintiff.type
            },
            // note: not used
            get_is_product_start_party_type_defendant(state) {
                return state.product_start.party_type == EnumPartyType.Defendant.type
            },
            // note: not used
            get_product_party_type(state) {
                return state.product_start.party_type
            },
            get_product_demand_letter(state) {
                return state.product_demand_letter
            },
            get_product_agreement_letter(state) {
                return state.product_agreement_letter
            },



            get_selected_state_small_claims_spec(state){
                return state.selected_state_small_claims_spec
            },
            get_is_user_logged_in(state) {
                return state.auth.token != null
            },
            get_auth_object(state) {
                return state.auth
            },
            get_auth_token(state) {
                return state.auth.token
            },
            get_auth_user_id(state) {
                return state.auth.user_id
            },
            get_auth_user_email(state) {
                return state.auth.user_email
            },
            get_auth_user_display_name(state) {
                if (isValidString(state.auth.user_display_name)){
                    return state.auth.user_display_name
                }
                return state.auth.user_email
            },
            /*
            get_is_auth_token_valid(state) {
                console.log("get_is_auth_token_valid()")

                const timeNow = now_in_unix()
                //console.log("timeNow = ", timeNow)

                //const secondsInHour = 3600
                const bufferedSecondsInHour = 60 * 50
                //const bufferedSecondsInHour = 60 * 15
                //console.log("expiration = ", bufferedSecondsInHour)

                const timeDifference = timeNow - state.auth.generated_at_unix
                console.log("timeDifference = ", timeDifference)

                if (timeDifference > bufferedSecondsInHour){
                    console.warn("token expired")
                    return false
                }
                console.log("token not yet expired")
                return true
            },
            */
            get_journey_progress_selected_product(state) {
                return state.journey_progress.selected_product
            },            
            

            /*
            // TO REMOVE LATER        
            getCount(state) {
                return state.count
            },
            getRawItemsCount(state) {
                return state.rawItems.length
            },
            */
        },

        actions: {
            set_session() {
                if (this.session != null){
                    console.log("session_id already exists")
                } else {
                    console.log("generate new session_id")

                    // https://stackoverflow.com/questions/10726909/random-alpha-numeric-string-in-javascript
                    // let session_id = Math.random().toString(36).slice(2)

                    let session_id;
                    while (!isValidString(session_id)) {
                        //console.log("session_id while-loop")
                        session_id = Math.random().toString(36).slice(2)
                    }
                    this.session = session_id
                }
                //console.log("session_id = ")
                //console.log(session_id)

                // no need to write session to firebase db
                // because this value is already added in
                // update_firebase_db_kvp() or update_firebase_db_dict()
                
                return this.session
            },
            set_product_start_attribute(key, value, updateCloud = true) {
                this.product_start[key] = value
                if (updateCloud) {
                    update_firebase_db_kvp("product_start/" + key, value)
                }
                return this.product_start[key]
            },
            set_product_start_from_cloud(dict) {
                this.product_start = dict
                return this.product_start
            },

            set_product_demand_letter_attribute(key, value, updateCloud = true) {
                this.product_demand_letter[key] = value
                if (updateCloud) {
                    update_firebase_db_kvp("product_demand_letter/" + key, value)
                }
                return this.product_demand_letter[key]
            },
            set_product_demand_letter_from_cloud(dict) {
                this.product_demand_letter = dict
                return this.product_demand_letter
            },

            set_product_agreement_letter_attribute(key, value, updateCloud = true) {
                this.product_agreement_letter[key] = value
                if (updateCloud) {
                    update_firebase_db_kvp("product_agreement_letter/" + key, value)
                }
                return this.product_agreement_letter[key]
            },
            set_product_agreement_letter_from_cloud(dict) {
                this.product_agreement_letter = dict
                return this.product_agreement_letter
            },            




            set_selected_state_small_claims_spec(selected_state){
                this.selected_state_small_claims_spec = getSmallClaimsSpecByState(selected_state)
                return this.selected_state_small_claims_spec
            },
            set_auth_object(token, email, id, displayName=null) {
                this.auth.token = token
                this.auth.user_email = email
                this.auth.user_id = id
                this.auth.user_display_name = displayName
                localStorage.setItem("token", token)    // firebase_token
                //localStorage.getItem('token')
                //this.auth.generated_at_unix = now_in_unix()
                return this.auth
            },
            update_auth_token(token){
                this.auth.token = token
                localStorage.setItem("token", token)    // firebase_token
                return this.auth.token
            },
            set_journey_progress_selected_product(value) {
                this.journey_progress.selected_product = value
                return this.journey_progress.selected_product                
            },                        




            /*
            // TO REMOVE LATER    
            changeCount(value) {
                this.count = this.count + value
                return this.count
            },            
            addRawItems(item) {
                this.rawItems.push(item)
                return this.rawItems
            },
            */
        },
        persist: true,        
    }
);


function update_firebase_db_kvp(key, value) {
    //console.log("update_firebase_db_kvp()")
    //console.log("get_is_user_logged_in = " + getStateMgmtStore().get_is_user_logged_in)

    let directory;
    let firebaseDatabase;
    if (getStateMgmtStore().get_is_user_logged_in) {
        //directory = 'user_id/' + firebaseAuthInstance.currentUser.uid
        directory = 'user_id/' + getStateMgmtStore().get_auth_user_id
        firebaseDatabase = firebaseDatabase_ux
    } else {
        directory = 'visitor/' + getStateMgmtStore().get_session
        firebaseDatabase = firebaseDatabase_visitors
    }

    const databaseReference = ref(
        firebaseDatabase,
        directory
    )

    // update data in firebase database
    let dict = {}
    dict[key] = value

    dict["_last_action_utc_iso"] = now_in_iso()                 // 2023-01-26T05:41:09
    dict["_last_action_pacific"] = now_in_pacific_locale()      // "9/30/2021, 11:59:59 PM"
    //console.log(dict)

    update(databaseReference, dict);
}

function update_firebase_db_dict(dict) {    
    console.warn(update_firebase_db_dict)
    console.warn("not used now")
    /*
    //console.log("update_firebase_db_dict()")
    //console.log("get_is_user_logged_in = " + getStateMgmtStore().get_is_user_logged_in)

    let directory;
    if(getStateMgmtStore().get_is_user_logged_in){
        //directory = 'user_id/' + firebaseAuthInstance.currentUser.uid
        directory = 'user_id/' + getStateMgmtStore().get_auth_user_id
    } else {
        directory = 'visitor/' + getStateMgmtStore().get_session
    }

    const databaseReference = ref(
        firebaseDatabase_ux,
        directory
    )

    dict["_last_action_utc_iso"] = now_in_iso()     // 2023-01-26T05:41:09
    //console.log(dict)

    update(databaseReference, dict);
    */

}