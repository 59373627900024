
export function now_in_iso() {
    // both UNIX and ISO are UTC-based
    // ISO example: 2023-01-26T05:41:09
    return (new Date).toISOString().substring(0, 19)
}

export function now_in_unix() {
    // both UNIX and ISO are UTC-based
    // UNIX example: 1675643786
    return Math.floor(Date.now() / 1000)
}

export function unix_to_iso(unix) {
    // both UNIX and ISO are UTC-based
    // UNIX example: 1675643786
    // ISO example: 2023-01-26T05:41:09
    return new Date(unix * 1000).toISOString().substring(0, 19)
}

export function unix_to_display(unix) {
    // UNIX example: 1675643786
    // https://www.tutorialrepublic.com/faq/how-to-convert-a-unix-timestamp-to-time-in-javascript.php
    //return new Date(unix * 1000).toLocaleDateString("default") // 2/5/2023
    return new Date(unix * 1000).toLocaleString("default") // 2/5/2023, 6:17:57 PM
}

export function iso_to_unix(iso) {
    // both UNIX and ISO are UTC-based
    // add 'Z' to put ISO into UTC timezone
    // ISO example: 2023-01-26T05:41:09
    return Math.floor(new Date(iso + 'Z').getTime() / 1000)
}

export function iso_to_display(iso) {
    // first convert ISO to UNIX
    const temp_unix = iso_to_unix(iso)
    //console.log("temp_unix = ")
    //console.log(temp_unix)
    // then use unix_to_display()
    return unix_to_display(temp_unix)
}


export function now_in_local_iso() {
    // 2023-03-30
    // DATE ONLY
    return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)
}


export function now_in_pacific_locale() {
    return (new Date).toLocaleString(
        "en-US", {timeZone: "America/Los_Angeles"}
    )

    /*
    var myDate = new Date(1633071599000)
    var pstDate = myDate.toLocaleString("en-US", {
        timeZone: "America/Los_Angeles"
    })
    "9/30/2021, 11:59:59 PM"
    */    
}