<!--
////////////////////////////////////////////////////////
//////// FIRST LINE OF CODE STARTS IN THIS FILE ////////
////////////////////////////////////////////////////////
-->
<template>
  <v-app id="app" class="grey darken-2">
    <NavBar />
    
    <v-main>
      <router-view></router-view>
    </v-main>

    <Footer />

  </v-app>
</template>


<script>
import NavBar from './ui/components/common/nav_bar.vue';
import Footer from './ui/components/common/footer.vue'
import ApiClient from './openapi/ApiClient'
import { getNetworkEnv, env } from './Globals'

// >>FLOW OF CODE LIFECYCLE<<
// FIRST LINE OF CODE STARTS HERE
//console.log("App.vue 0")


// ============================================================
// ====================== Global Setup =======================
const currentEnv = getNetworkEnv()

if (currentEnv == env.PROD){
  // PRODUCTION SETUP
  //console.log("Welcome to FillMeForms!")
  console.info = function(){};
  console.log = function(){};
  console.warn = function(){};
  console.error = function(){};
  console.log("logs hidden for production")
  
  /*
   sets where network API points to
   NO CHANGES FOR PRODUCTION
   see hostSettings() in ApiClient.js
  */
  //ApiClient.instance.basePath = 'https://api.fillmeforms.com/'.replace(/\/+$/, '');
  //ApiClient.instance.basePath = ApiClient.instance.getBasePathFromSettings(0)
  ////////////////////////

  // REMEMBER to Configure CORS
  // this is to support downloading files from Google Cloud Storage Bucket
  // https://firebase.google.com/docs/storage/web/download-files#cors_configuration
  // COMMAND: gsutil cors set cors.json gs://<your-cloud-storage-bucket>

} else {
  // NON-PROD SETUP
  /*
  //console.info("info")
  //console.debug("debug") // == verbose
  //console.log("log")
  console.warn("mimic Production Logging setup")
  console.info = function(){};
  console.log = function(){};
  console.warn = function(){};
  console.error = function(){};
  */
  
  /*
   sets where network API points to
   see hostSettings() in ApiClient.js
  */  
  if (currentEnv === env.DEV){
    //console.log("instance pointed to -- DEV")
    //ApiClient.instance.basePath = 'https://dev-api.fillmeforms.com/'.replace(/\/+$/, '');
    ApiClient.instance.basePath = ApiClient.instance.getBasePathFromSettings(1)
  } else if (currentEnv === env.LOCAL){
    //console.log("instance pointed to -- LOCAL")
    //ApiClient.instance.basePath = 'http://localhost:8080/'.replace(/\/+$/, '');
    ApiClient.instance.basePath = ApiClient.instance.getBasePathFromSettings(2)
  } else {
    //console.log("instance pointed to -- OTHER")
    ApiClient.instance.basePath = ApiClient.instance.getBasePathFromSettings(1)
  }

}
console.log("ApiClient.instance.basePath = ")
console.log(ApiClient.instance.basePath)
console.log("")

//const auth = getAuth();
//connectAuthEmulator(auth, "http://localhost:9099");

/*
if (!firebase.apps.length) {
   firebase.initializeApp({});
}else {
   firebase.app(); // if already initialized, use that one
}
*/

// ============================================================
// App.vue - note that what is relevant for Google
// is the 'name' meta tag, the 'description' and the robots meta tag
// using the 'keywords' meta tag won't help, as Google ignores it




// >>FLOW OF CODE LIFECYCLE<<
//console.log("App.vue 1")
// *CODE THEN GOES TO main.js*


export default {
  name: 'App',
  metaInfo(){
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "FillMeForms - Make legal service avaliable to all",
      // all titles will be injected into this template
      titleTemplate: '%s | FillMeForms',
      meta: [
        { 
          vmid: 'description',
          name: 'description',
          content:'Use our transparent and guided system to write a demand letter that formally requests the money you are owed in minutes for FREE!'
        }
      ]
    }
  },
  components: { 
    NavBar,
    Footer,
  },

  data: () => ({
    //
  }),
  
  beforeCreate: function(){
    //console.log("App.vue -- beforeCreate")
  },
  created: function(){
    //console.log("App.vue -- created")
  },
  beforeMount: function(){
    //console.log("App.vue -- beforeMount")
  },
  mounted: function(){
    //console.log("App.vue -- mounted")
  },
  beforeDestroy: function(){
    //console.log("App.vue -- beforeDestroy")
  },
  destroyed: function(){
    //console.log("App.vue -- destroyed")
  },
  beforeUpdate: function(){
    //console.log("App.vue -- beforeUpdate")
  },
  updated: function(){
    //console.log("App.vue -- updated")
  }

};

</script>
