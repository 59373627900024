<template>
  <div id="footer">
    <!-- Footer -->
    <v-footer dark padless class="indigo lighten-1">
      <v-card width="800px" flat class="indigo lighten-1 white--text text-center mx-auto">
        <!--
          <v-card-text>
            <v-btn
              v-for="icon in icons"
              :key="icon"
              class="mx-4 white--text"
              icon
              @click="footerIconClick(icon)"
            >
              <v-icon size="36px">
                {{ icon }}
              </v-icon>
            </v-btn>
          </v-card-text>
          -->

        <v-card-text class="white--text pt-5">
          <!--
            <h4>Protect Yourself From Legal Dispute Today</h4>
            <h4>Make Legal Services Available to All</h4>
          -->
          <div v-html="footer_main_html_text" />
          <br>
          <div v-html="footer_copyright_html_text" />
          
          <!--
          <div v-html="footer_email_html_text" />
          -->
          <!--
              © &copy; 
              <div v-html="footer_copyright_text" />
              <strong> &copy;{{ new Date().getFullYear() }} by FillMeForms LLC </strong>
          -->
        </v-card-text>

        <!--
          <v-divider></v-divider>
        -->

        <!--
        <v-card-text class="white--text">
          By usine FillMeForms, you agree to these
          <a class="white--text" href="/terms">Terms of Service</a>
           and 
          <a class="white--text" href="/privacy">Privacy Policy</a>.
        </v-card-text>
        -->



        <!--
        <v-card-text class="white--text">
          <div v-html="footer_copyright_text" />
        </v-card-text>
        -->

      </v-card>

    </v-footer>

  </div>

</template>

<script>

import { getHyperlinkNewTabText } from "../../../utils/HtmlTextHandler"
import { getHyperlinkText } from "../../../utils/HtmlTextHandler"
import { FMF_URLs } from '../../../router/index.js'

export default {

  data() {
    return {

      //footer_main_html_text: "FillMeForms is a website for online legal information and self-help service. FillMeForms is neither an attorney nor a law firm. The information provided does not constitute legal advice. Your use of FillMeForms services does not establish an attorney-client relationship.",
      footer_main_html_text: "By using FillMeForms, you agree to our" 
                            + "<br>"
                            + getHyperlinkNewTabText(
                              "Terms of Service",
                              FMF_URLs.TERMS.url,   //"https://www.fillmeforms.com/terms"
                              "white"
                              )
                            + " and "
                            + getHyperlinkNewTabText(
                                "Privacy Policy",
                                FMF_URLs.PRIVACY.url,   //"https://www.fillmeforms.com/privacy"
                                "white"
                                )
                            ,

      footer_copyright_html_text: "<strong> &copy;" + new Date().getFullYear() + " FillMeForms LLC </strong>",

      //footer_email_html_text: "contact@fillmeforms.com",

      icons: [
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
        'mdi-instagram',
      ],      

    }
  },
  methods:{
    /*
    footerIconClick(icon){
      getAuth().signOut().then(function() {
        console.log('Signed Out');
        //localStorage.setItem('firebase_token', '')
        localStorage.clear()
      }, function(error) {
        console.error('Sign Out Error', error);
      });
    }
    */
  }

}

</script>