// ENUM class

export const EnumJourneyCardStepStatus = Object.freeze({

    NEW: { type: "new" },                   // first arrive, not started anything
    IN_PROGRESS: { type: "in-progress" },   // started, but not done with letter
    UNPAID: { type: "unpaid" },             // done with letter, not yet paid
    PAID: { type: "paid" },                 // paid

    //PENDING: { type: "Pending" },           // done with letter - FREE
    //COMPLETED: { type: "Completed" },       // done with letter and paid

});