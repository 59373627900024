import { CheckAuthStatusRequest, DefaultApi } from '../openapi';
import { StatusCodes } from 'http-status-codes';
import { getStateMgmtStore } from '../stores/ux_state_store.js';

import { fmf_error_events } from '../utils/AnalyticsHandler';

import Bugsnag from '@bugsnag/js'

export function fmf_CheckAuthStatus(
    onResolve, onReject
) {
    console.log("fmf_CheckAuthStatus()")

    let apiInstance = new DefaultApi();
    let request = new CheckAuthStatusRequest();

    request.token = getStateMgmtStore().get_auth_token

    apiInstance.checkAuthStatus(
        request,
        (error, data, response) => {

            if (error) {
                console.error("PROMISE FAILURE = ")
                console.error(error)
                console.error("FMF_INCIDENT")
                Bugsnag.notify(new Error(error))


                fmf_error_events(
                    "_error",
                    "api",
                    {
                        api: "fmf_CheckAuthStatus",
                        issue: error
                    }
                )
                return onReject(error.code, error.message)
            }

            //console.log("complete data = ")
            //console.log(data)
            // ^^ NOTE: data can be null, usually for non-200 responses

            if (data != null && data.code === StatusCodes.OK) {
                console.log("PROMISE & API SUCCESS")

                if (data.data != null) {
                    let jsonResponseString = JSON.stringify(data.data);
                    let jsonResponseObject = JSON.parse(jsonResponseString)

                    //console.log("jsonResponseString = ")
                    //console.log(jsonResponseString)
                    //console.log("")
                    console.log("jsonResponseObject = ")
                    console.log(jsonResponseObject)

                    return onResolve(jsonResponseObject)

                } else {

                    // the API call is successful (returned 200)
                    // there's just no JSON body; this should not happen
                    console.error("response.data is NULL")
                    console.error("FMF_INCIDENT")
                    Bugsnag.notify("response.data is NULL")


                    fmf_error_events(
                        "_error",
                        "api",
                        {
                            api: "fmf_CheckAuthStatus",
                            issue: "success but no response body"
                        }
                    )
                    return onResolve(null)

                }

            } else {
                console.error("API NEGATIVE CASE")

                if (data != null) {
                    let responseCode = data.code;
                    console.error("responseCode = ", responseCode)
                    console.error("FMF_INCIDENT")
                    Bugsnag.notify(new Error(data))


                    fmf_error_events(
                        "_error",
                        "api",
                        {
                            api: "fmf_CheckAuthStatus",
                            issue: data
                        }
                    )
                    // example:
                    // {"code": 401, "data": null, "message": "Please login again", "status": "Unauthorized"}
                    // data.DATA likely NULL
                    // data.MESSAGE exists
                    return onReject(responseCode, data.message)
                }
                // the API call is NOT successful (did not return 200)
                // there's just no message or info; this should not happen
                return onReject(0, null)
            }

        }
    );

    // out side API call

}