export const EnumProduct = Object.freeze({

    DemandLetter: { 
        type: "DemandLetter",
        brand: "LetterGenius",
        product: "Demand Letter",
    },
    AgreementLetter: { 
        type: "AgreementLetter",
        brand: "LetterGenius",
        product: "Mutual Agreement",
    },
    CourtPapers: { 
        type: "CourtPapers",
        brand: "CaseMate",
        product: "CaseMate",
    },
    CasePacket: { 
        type: "CasePacket",
        brand: "BriefEase",
        product: "BriefEase",
    },

});