// this file is original copy of index.js

import Vue from 'vue'
import VueRouter from 'vue-router'

import JOURNEY from '../ui/views/general/Journey.vue'

import { getStateMgmtStore } from '../stores/ux_state_store.js'
import { getNetworkEnv, env } from '../Globals.js'


Vue.use(VueRouter)

export const FMF_URLs = Object.freeze({
  HOME: { name: "home", url: "https://www.fillmeforms.com/" },
  TERMS: { name: "terms", url: "https://www.fillmeforms.com/terms" },
  PRIVACY: { name: "privacy", url: "https://www.fillmeforms.com/privacy" },
  PRICING: { name: "pricing", url: "https://www.fillmeforms.com/pricing"},
  ABOUT: { name: "about", url: "https://www.fillmeforms.com/about" },
  FAQ: { name: "faq", url: "https://www.fillmeforms.com/faq" },
  BLOG: { name: "blog", url: "https://www.fillmeforms.com/blog" },
  NOT_FOUND: { name: "not_found", url: "https://www.fillmeforms.com/not_found" },
  
  APPOINTMENT: { name: "appointment", url: "https://www.fillmeforms.com/appointment" },

  ABOUT_PRODUCTS: { name: "about_all_products", url: "https://www.fillmeforms.com/products" },
  ABOUT_DEMAND_LETTER: { name: "about_demand_letter", url: "https://www.fillmeforms.com/products-demand-letter" },
  ABOUT_MUTUAL_AGREEMENT: { name: "about_mutual_agreement", url: "https://www.fillmeforms.com/products-mutual-agreement" },
  ABOUT_CASE_MATE: { name: "about_case_mate", url: "https://www.fillmeforms.com/products-casemate" },
  ABOUT_BRIEF_EASE: { name: "about_brief_ease", url: "https://www.fillmeforms.com/products-briefease" },
  
  DEMAND_LETTER_PAYMENT: { name: "demand_letter_payment", url: "https://www.fillmeforms.com/demand-letter-plan" },  
  AGREEMENT_LETTER_PAYMENT: { name: "mutual_agreement_payment", url: "https://www.fillmeforms.com/mutual-agreement-plan" },
  COURT_PAPERS_PAYMENT: { name: "case_mate_payment", url: "https://www.fillmeforms.com/appointment" },
  CASE_PACKET_PAYMENT: { name: "brief_ease_payment", url: "https://www.fillmeforms.com/appointment" },


  // internal play page
  //https://www.fillmeforms.com/play

})

export const RoutesMap = Object.freeze({
  //DEV
  DEV_1: { path: "/dev1", name: "dev1" },
  DEV_2: { path: "/dev2", name: "dev2" },
  DEV_3: { path: "/dev3", name: "dev3" },

  //general 
  WEBAPP_ROOT: { path: "/", name: "root" },
  NOT_FOUND_REDIRECT: { path: "/not-found-redirect", name: "not-found-redirect" },

  // auth
  //SIGN_UP: { path: "/signup", name: "sign_up" },
  LOGIN: { path: "/login", name: "login" },
  RESET_PASSWORD: { path: "/reset-password", name: "reset-password" },

  // ACCT
  JOURNEY: { path: "/product", name: "product-home" },
  DOCUMENTS: { path: "/documents", name: "documents" },

  // PRODUCT
  PRODUCT_START: { path: "/product/start", name: "product-start" },
  PRODUCT_DEMAND_LETTER: { path: "/product/demand-letter", name: "product-demand-letter" },
  PRODUCT_AGREEMENT_LETTER: { path: "/product/mutual-agreement", name: "product-mutual-agreement" },
  PRODUCT_COURT_PAPERS: { path: "/product/case-mate", name: "product-case-mate" },
  PRODUCT_PREPARE_CASE: { path: "/product/brief-ease", name: "product-brief-ease" },

  // PAYMENT REDIRECT
  PAYMENT_COMPLETED_REDIRECT: { path: "/payment-completed", name: "payment-completed" },
  // http://localhost:8989/payment_completed?from=square_web&product=DemandLetter
  // http://localhost:8989/payment_completed?from=square_web&product=MutualAgreement
  // htts://secure.fillmeforms.com/payment_completed?from=square_web&product=DemandLetter
  // htts://secure.fillmeforms.com/payment_completed?from=square_web&product=MutualAgreement

  // remove this later
  //PRODUCT_ASSESS_CASE: { path: "/product/assess-case", name: "product-assess-case" }, // remove later
  //PRODUCT_PREVIEW: { path: "/product/preview", name: "product/preview" },

})


const routes = [
  // ===== DEV =====
  {
    path: RoutesMap.DEV_1.path,
    name: RoutesMap.DEV_1.name,
    //component: DevStart1,
    component: () => import(
      /* webpackChunkName: "Dev1" */
      '../ui/views/_dev/DevPage1.vue'
    ),
    meta: { requiresAuth: false },
    props: true
  },
  {
    path: RoutesMap.DEV_2.path,
    name: RoutesMap.DEV_2.name,
    //component: DevStart2,
    component: () => import(
      /* webpackChunkName: "Dev2" */
      '../ui/views/_dev/DevPage2.vue'
    ),
    meta: { requiresAuth: false },
    props: true
  },
  {
    path: RoutesMap.DEV_3.path,
    name: RoutesMap.DEV_3.name,
    //component: DevStart3,
    component: () => import(
      /* webpackChunkName: "Dev3" */
      '../ui/views/_dev/DevPage3.vue'
    ),
    meta: { requiresAuth: true },
    //meta: { requiresAuth: false },
    props: true
  },


  // ===== HOME =====
  {
    path: RoutesMap.WEBAPP_ROOT.path,
    name: RoutesMap.WEBAPP_ROOT.name,
    //component: DevStart1,         //DEVELOP
    //component: PROUDCT_START,   // PRODUCTION?
    component: JOURNEY,         // PRODUCTION?
    meta: { requiresAuth: false },

    beforeEnter(to, from, next) {
      console.log("RoutesMap.WEBAPP_ROOT")
      console.log("local NAV GUARD: beforeEnter()")
      //console.log("from:", from.fullPath)
      //console.log("to:", to.fullPath)

      // Put the full page URL including the protocol http(s) below
      //console.log("location.href = ", FMF_URLs.HOME.url)
      //location.href = FMF_URLs.HOME.url

      next()
    }
  },

  // ===== AUTH =====
  {
    path: RoutesMap.LOGIN.path,
    name: RoutesMap.LOGIN.name,
    component: () => import(
      /* webpackChunkName: "Login" */
      '../ui/views/auth/Login.vue'
    ),
    meta: { requiresAuth: false },
    props: true,
  },

  {
    path: RoutesMap.RESET_PASSWORD.path,
    name: RoutesMap.RESET_PASSWORD.name,
    component: () => import(
      /* webpackChunkName: "ForgotPassword" */
      '../ui/views/auth/ForgotPassword.vue'
    ),
    meta: { requiresAuth: false },
    props: true
  },
  /*
  {
    path: RoutesMap.SIGN_UP.path,
    name: RoutesMap.SIGN_UP.name,
    component: () => import(
      // webpackChunkName: "Signup"
      '../ui/views/auth/Signup.vue'
    ),
    meta: { requiresAuth: false },
    props: true
  },
  */
  /*
  {
    path: '/faq/:other',
    name: 'FAQ',
    component: () => import('../ui/components/support/FAQ.vue'),
    props: true,
    
    children: [
        {
            //path: ':hash',
            path: '/:hashkey',
            //name: 'About',
            //component: () => import('../views/About.vue'),
            //props: true
        },
    ]
    
  },
  */ 

  // ===== PRODUCT =====
  {
    path: RoutesMap.PRODUCT_START.path,
    name: RoutesMap.PRODUCT_START.name,
    component: () => import(
      /* webpackChunkName: "Start" */
      '../ui/views/product/Start.vue'
    ),
    meta: { requiresAuth: false },
    props: true
  },
  {
    path: RoutesMap.PRODUCT_DEMAND_LETTER.path,
    name: RoutesMap.PRODUCT_DEMAND_LETTER.name,
    component: () => import(
      /* webpackChunkName: "DemandLetter" */
      '../ui/views/product/DemandLetter.vue'
    ),
    meta: { requiresAuth: true },
    props: true
  },  
  {
    path: RoutesMap.PRODUCT_AGREEMENT_LETTER.path,
    name: RoutesMap.PRODUCT_AGREEMENT_LETTER.name,
    component: () => import(
      /* webpackChunkName: "AgreementLetter" */
      '../ui/views/product/AgreementLetter.vue'
    ),
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: RoutesMap.PRODUCT_COURT_PAPERS.path,
    name: RoutesMap.PRODUCT_COURT_PAPERS.name,
    component: () => import(
      /* webpackChunkName: "CourtPapers" */
      '../ui/views/product/CourtPapers.vue'
    ),
    meta: { requiresAuth: true },
    props: true
  },
  // CasePacket next


  // ===== ACCT =====
  {
    path: RoutesMap.JOURNEY.path,
    name: RoutesMap.JOURNEY.name,
    component: () => import(
      /* webpackChunkName: "ProductHome" */
      //'../ui/views/general/ProductHome.vue'
      '../ui/views/general/Journey.vue'
    ),
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: RoutesMap.DOCUMENTS.path,
    name: RoutesMap.DOCUMENTS.name,
    component: () => import(
      /* webpackChunkName: "Documents" */
      '../ui/views/general/Documents.vue'
    ),
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: RoutesMap.PAYMENT_COMPLETED_REDIRECT.path,
    name: RoutesMap.PAYMENT_COMPLETED_REDIRECT.name,
    component: () => import(
      /* webpackChunkName: "PaymentCompleted" */
      '../ui/views/general/PaymentCompleted.vue'
    ),
    meta: { requiresAuth: false },
    props: true
  },


  // ===== OTHER =====
  {
    path: RoutesMap.NOT_FOUND_REDIRECT.path,
    name: RoutesMap.NOT_FOUND_REDIRECT.name,
    alias: "*",
    meta: { requiresAuth: false },
    props: true,
    beforeEnter(to, from, next) {
      console.log("RoutesMap.NOT_FOUND_REDIRECT")
      console.error("NOT_FOUND NAV GUARD: beforeEnter()")
      //console.log("from:", from.fullPath)
      //console.log("to:", to.fullPath)
      
      console.log("location.href = ", FMF_URLs.NOT_FOUND.url)
      location.href = FMF_URLs.NOT_FOUND.url
      
      // end progress here, no need to call next()
      //next()  
    }    
  },

]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})



// GLOBAL NAVGIATION GUARD
router.beforeEach((to, from, next) => {
  //console.log("--GLOBAL NAV GUARD: beforeEach()--")
  //console.log("from:", from.fullPath)
  //console.log("to:", to.fullPath)

  const blockDevPages = getNetworkEnv() == env.PROD
  
  // for dev work or testing only below
  //const blockDevPages = getNetworkEnv() != env.PROD
  
  //console.log("blockDevPages = ", blockDevPages)
  if (blockDevPages){
    console.warn("in env where Dev Pages should be blocked")
    // in PROD env, ignore DEV paths
    const matchDev1 = to.fullPath == RoutesMap.DEV_1.path
    const matchDev2 = to.fullPath == RoutesMap.DEV_2.path
    const matchDev3 = to.fullPath == RoutesMap.DEV_3.path

    if (matchDev1 || matchDev2 || matchDev3){
      console.error("matched DevPage Path")
      // STOP HERE
      
      // cannot go to Dev Pages in PROD
      // redirect to NOT FOUND page
      
      location.href = FMF_URLs.NOT_FOUND.url
      
      // no need to call next()...
      // ... calling next() will show dev page for a blink moment

      // end here
      console.error("NavGuard blocked going to DevPage")
      console.error("Page = ", to.fullPath)
      return
    }
  }

  // above took care of DevPages in Prod Env
  // below is flow for all other pages

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const is_user_logged_in = getStateMgmtStore().get_is_user_logged_in

  // user not logged in but is trying to access page that 'requiresAuth'
  if (!is_user_logged_in && requiresAuth) {    
    // follows example:
    // https://router.vuejs.org/guide/advanced/navigation-guards.html#optional-third-argument-next

    console.warn("GLOBAL NAV GUARD: blocked")
    console.warn(to.fullPath)
    console.warn("unauthorize to access page without token")

    // https://stackoverflow.com/questions/68938225/how-to-get-around-vue-router-infinite-redirection-error
    // return next({ name: RoutesMap.LOGIN.name })
    // Uncaught (in promise) Error: Redirected when going from "/dev2" to "/dev3" via a navigation guard.
    // ^^ use router.push() to prevent issue above ^^
    router.push({ name: RoutesMap.LOGIN.name });
  } else {
    //console.log("GLOBAL NAV GUARD: normal")
    // THIS IS NEEDED
    next()
  }
  //console.log("---GLOBAL NAV GUARD - passed AuthToken check---")
})


export default router

