// this file is to set the GLOBAL CONFIG FLAGS


//  use-case: indicate the network environment web app points to
export const env = Object.freeze({
    LOCAL: { 
        name: "local",
        gCloudProjectId: null,
        firebaseStorageBucket: "gs://dev-fillmeforms.appspot.com",
    },
    DEV: { 
        name: "dev",
        gCloudProjectId: "dev-fillmeforms",
        firebaseStorageBucket: "gs://dev-fillmeforms.appspot.com",
    },
    // currently don't have
    TEST: { 
        name: "test",
        gCloudProjectId: null,
        firebaseStorageBucket: null,
    },
    // currently don't have
    STAGE: {
        name: "stage",
        gCloudProjectId: null,
        firebaseStorageBucket: null,
    },
    PROD: {
        name: "prod",
        gCloudProjectId: "prod-fillmeforms",
        firebaseStorageBucket: "gs://prod-fillmeforms.appspot.com",
    }
});

export function getNetworkEnv() {
    //return env.LOCAL
    //return env.DEV
    // ===========================
    //return env.TEST             // currently don't have
    //return env.STAGE            // currently don't have
    return env.PROD
}

