<template>
    <div id="view_journey">

        <!--
            https://vuetify.cn/en/components/alerts/
        -->
        <v-alert
          text
          dense
          v-show="alert_setup.show"
          :color="alert_setup.color"
          :type="alert_setup.type"
          :icon="alert_setup.icon"
          :border="alert_setup.border"
        >
          {{  alert_setup.text }}
        </v-alert>

        <JourneyPage_PopUp
            ref="JourneyPage_PopUp"
            v-bind:PROP_view_model="JourneyPage_PopUp_ViewModel"
            v-bind:PROP_show_popup="SHOW_JourneyPage_PopUp"
            @onProceed="onProceed_JourneyPage_PopUp"
        />


        <!--
########################################################################
########################################################################
########################################################################
        -->

        <!--
            SKELETON SECTION
            OLD: v-show="is_card_loading"
            NOW: use v-if and v-else
        -->
        <v-container
            class="pa-5"
            v-if="is_card_loading"
        >
            <v-skeleton-loader
                class="mx-auto"
                type="paragraph"
            ></v-skeleton-loader>

            <v-layout wrap>
                <v-flex xs12 sm12 md6 lg6 xl6 class='pa-5'
                >
                    <v-skeleton-loader
                        class="mx-auto"
                        type="list-three-line, image, article"
                    ></v-skeleton-loader>
                </v-flex>

                <v-flex xs12 sm12 md6 lg6 xl6 class='pa-5'
                >
                    <v-skeleton-loader
                        class="mx-auto"
                        type="list-three-line, image, article"
                    ></v-skeleton-loader>
                </v-flex>

                <v-flex xs12 sm12 md6 lg6 xl6 class='pa-5'
                >
                    <v-skeleton-loader
                        class="mx-auto"
                        type="list-three-line, image, article"
                    ></v-skeleton-loader>
                </v-flex>


                <v-flex xs12 sm12 md6 lg6 xl6 class='pa-5'
                >
                    <v-skeleton-loader
                        class="mx-auto"
                        type="list-three-line, image, article"
                    ></v-skeleton-loader>
                </v-flex>

            </v-layout>

        </v-container>


        <!--
            MAIN SECTION
            OLD: v-show="!is_card_loading"
            NOW: use v-if and v-else
        -->
        <v-container
            class="pa-5"
            v-else
        >
            <h1
                class="text-center display-1"
            >
                <div v-html="journey_page_title_html_text" />
            </h1>

            <h2
                class="text-center pa-5 font-weight-regular grey--text text--darken-3"
            >
                <div v-html="journey_page_desc_html_text" />
            </h2>


            <v-layout wrap>
                <v-flex xs12 sm12 md6 lg6 xl6 class='pa-5'
                >
                    <JourneyCard_DemandLetter
                        ref="JourneyCard_DemandLetter"
                        v-bind:PROP_view_model="JourneyCard_DemandLetter_ViewModel"
                        @onProceed="onProceed_JourneyCard_DemandLetter"
                    />

                </v-flex>

                <v-flex xs12 sm12 md6 lg6 xl6 class='pa-5'
                >
                    <JourneyCard_AgreementLetter
                        ref="JourneyCard_AgreementLetter"
                        v-bind:PROP_view_model="JourneyCard_AgreementLetter_ViewModel"
                        @onProceed="onProceed_JourneyCard_AgreementLetter"
                    />
                </v-flex>

                <v-flex xs12 sm12 md6 lg6 xl6 class='pa-5'
                >
                    <JourneyCard_CourtPapers
                        ref="JourneyCard_CourtPapers"
                        v-bind:PROP_view_model="JourneyCard_CourtPapers_ViewModel"
                        @onProceed="onProceed_JourneyCard_CourtPapers"

                    />

                </v-flex>


                <v-flex xs12 sm12 md6 lg6 xl6 class='pa-5'
                >
                    <JourneyCard_CasePacket
                        ref="JourneyCard_CasePacket"
                        v-bind:PROP_view_model="JourneyCard_CasePacket_ViewModel"
                        @onProceed="onProceed_JourneyCard_CasePacket"
                    />

                </v-flex>

            </v-layout>


            <div class="my-15"></div>
            <v-row align="center" justify="center">
                <v-btn
                    class="white--text"
                    v-bind:color="'indigo'"
                    @click="change_setup()"
                >
                    {{ change_setup_button_text }}
                </v-btn>
                <!--
                    @click="change_setup()"
                    @click="onCheckAuthStatus()"
                -->
            </v-row>
            <div class="my-10"></div>

        </v-container>

    </div>
</template>




<script>

import JourneyPage_PopUp from "../../components/common/pop_up.vue"

import JourneyCard_DemandLetter from "../../components/common/journey_card.vue"
import JourneyCard_AgreementLetter from "../../components/common/journey_card.vue"
import JourneyCard_CourtPapers from "../../components/common/journey_card.vue"
import JourneyCard_CasePacket from "../../components/common/journey_card.vue"

import { fmf_event, fmf_error_events } from '../../../utils/AnalyticsHandler.js'
import { FMF_URLs } from '../../../router/index'
import { RoutesMap } from '../../../router/index'
import { EnumProduct } from '../../../enums/EnumProduct'
import { EnumJourneyCardButtonAction } from '../../../enums/EnumJourneyCardButtonAction'
import { EnumJourneyCardStepStatus } from "../../../enums/EnumJourneyCardStepStatus"

import { getStateMgmtStore } from '../../../stores/ux_state_store.js'
import { isValidString } from "../../../utils/ValidChecker"
import { getRedirectUrl } from "../../../utils/RedirectUrlHandler.js"

import { env } from "../../../Globals.js"

import { ref } from "firebase/database";
import { get } from "firebase/database";
import { child } from "firebase/database";
import { getIndigoText, getIndigoBoldText } from "../../../utils/HtmlTextHandler"
import { getItalicizedText } from "../../../utils/HtmlTextHandler"
import { getSmallText } from "../../../utils/HtmlTextHandler"

import { fmf_CheckAuthStatus } from "../../../models/CheckAuthStatusModel.js"
import { fmf_Refresh_Token } from "../../../models/RefreshTokenModel.js"

import Bugsnag from '@bugsnag/js'

export default {
    components: {
        JourneyPage_PopUp,

        JourneyCard_DemandLetter,
        JourneyCard_AgreementLetter,
        JourneyCard_CourtPapers,
        JourneyCard_CasePacket,
    },
    //////////////////////////////////////////////////////
    //////////////// LIFE-CYCLE FUNCTIONS ////////////////
    //////////////////////////////////////////////////////
    created() {
        //console.log(this.CLASS_NAME, " - created LIFECYCLE")
        window.scrollTo(0, 0)

        // this.is_card_loading is defaulted to TRUE to start
        // this.is_card_loading = true

        fmf_Refresh_Token(
            this.load_ux_record(
                this.populate_ui,
                this.relogin
            )
        )
    },

    //////////////////////////////////////////////////////
    ///////////////// REACTIVE VARIABLES /////////////////
    //////////////////////////////////////////////////////
    data() {
        return {
            CLASS_NAME: "Journey",

            change_setup_button_text: "start a new case",

            JourneyPage_PopUp_ViewModel:{},
            SHOW_JourneyPage_PopUp: false,

            JourneyCard_DemandLetter_ViewModel: {},
            JourneyCard_AgreementLetter_ViewModel: {},
            JourneyCard_CourtPapers_ViewModel: {},
            JourneyCard_CasePacket_ViewModel: {},

            // default to TRUE to indicate loading at page creation
            is_card_loading: true,

        }
    },
    props: {
        /*
        payment: {
            type: String,
            required: false
        },
        */
    },
    watch: {
        /*
        is_card_loading: {
            handler: function (newVal, oldVal) {
                console.log('is_card_loading changed: ', newVal, ' | was: ', oldVal)

                if (newVal) {
                    // loading - no UI
                } else {
                    // loading finished - populate UI
                    console.log("calling populate_ui() from watch handler")
                    this.populate_ui()
                }

            },
            //deep: true
        },
        */

    },
    computed: {
        alert_setup(){

            let display = false
            let txt = ''

            const agreement_letter_store = getStateMgmtStore(this.$piniaInstance).product_agreement_letter
            const demand_letter_store = getStateMgmtStore(this.$piniaInstance).product_demand_letter

            const agreement_paid_timestamp = agreement_letter_store.paid_timestamp_utc_iso
            const demand_paid_timestamp = demand_letter_store.paid_timestamp_utc_iso

            //const agreement_paid_file_path = agreement_letter_store.paid_storage_path
            //const demand_paid_file_path = demand_letter_store.paid_storage_path
            
            const agreement_paid_doc_name = agreement_letter_store.paid_document_name
            const demand_paid_doc_name = demand_letter_store.paid_document_name

            if (agreement_paid_timestamp || demand_paid_timestamp) {
                // at least one PAID product

                // display FLIPS to true
                display = true

                // below uses an overload/overwrite style
                // ie, the inner/later setup overwrite the previous setup
                if (isValidString(agreement_paid_timestamp)) {
                    // agreement letter has receipt_id BUT NOT file path
                    txt = "Thank you for your payment! Our team is processing your document. We'll send you an update shortly within the next few hours!"
                    if (isValidString(agreement_paid_doc_name)) {
                        // agreement letter has receipt_id and file path
                        txt = "Your document is processed! You can check the status below. Don't hesitate to contact us if you have any questions."
                        if (isValidString(demand_paid_timestamp)) {
                            // agreement letter has receipt_id and file path
                            // demand letter has receipt_id BUT NOT file path
                            txt = "Thank you for your payment! Our team is processing your document. We'll send you an update shortly within the next few hours!"
                            if (isValidString(demand_paid_doc_name)) {
                                // both files have receipt_ids and file paths
                                txt = "Your documents are processed! You can check the status below. Don't hesitate to contact us if you have any questions."
                            }
                        }
                    }
                } else {
                    // agreement letter DOES NOT HAVE receipt_id
                    // now just check demand letter
                    if (isValidString(demand_paid_timestamp)) {
                        // demand letter has receipt_id BUT NOT file path
                        txt = "Thank you for your payment! Our team is processing your document. We'll send you an update shortly within the next few hours!"
                        if (isValidString(demand_paid_doc_name)) {
                            // demand letter has receipt_id and file path
                            txt = "Your document is processed! You can check the status below. Don't hesitate to contact us if you have any questions."
                        }
                    }
                }
            }
            // NO else-clause
            // ie, display REMAINS false when above is NOT entered


            // testing overrides
            //display = true
            //display = false

            //https://vuetify.cn/en/components/alerts/
            let alert = {
                show: display,
                icon: "mdi-check-decagram",
                type: "success",
                color: "teal",
                border: "left",
                text: txt,
            }

            //console.log("alert = ", alert)

            return alert
        },
        journey_page_title_html_text() {
            let html = ''

            const displayName = getStateMgmtStore(this.$piniaInstance).get_auth_user_display_name

            html = '&#128075;' //👋
            html = html + ' ' + getIndigoBoldText(displayName)

            return html
        },
        journey_page_desc_html_text(){
            let html = ''
            html = 'Welcome back! Continue where you left off.'

            //html = html + 'Welcome back! Continue where you left off.'
            /*
            const party_type = getStateMgmtStore(this.$piniaInstance).get_primary_case.party_type

            const sue_in_state = getStateMgmtStore(this.$piniaInstance).get_primary_case.sue_in_state
            let demand_amount
            if (getStateMgmtStore(this.$piniaInstance).get_primary_case.demand_amount != null) {
                demand_amount = getStateMgmtStore(this.$piniaInstance).get_primary_case.demand_amount
            } else {
                demand_amount = 0
            }
            //const demand_amount = getStateMgmtStore(this.$piniaInstance).get_primary_case.demand_amount
            const plaintiff_type = getStateMgmtStore(this.$piniaInstance).get_primary_case.plaintiff_type
            const defendant_type = getStateMgmtStore(this.$piniaInstance).get_primary_case.defendant_type

            let plaintiff_type_text;
            if (plaintiff_type == EnumPartyType.Individual.type) {
                plaintiff_type_text = "yourself"
            } else if (plaintiff_type == EnumPartyType.Business.type) {
                plaintiff_type_text = "a business"
            }

            let defendant_type_text;
            if (defendant_type == EnumPartyType.Individual.type) {
                defendant_type_text = "an individual"
            } else if (defendant_type == EnumPartyType.Business.type) {
                defendant_type_text = "a business"
            }

            const html =
                'I am a <a class="indigo--text">' + party_type + '</a>'
                + ' representing '
                +'<a class="indigo--text">' + plaintiff_type_text + '</a>' + '.'
                + '<br><br>'
                + ' You want to file a small claims case against '
                + '<a class="indigo--text">' + defendant_type_text + '</a>'
                + ' in '
                + '<a class="indigo--text">' + sue_in_state + '</a>' + '.'
                + '<br><br>'
                + 'The amount you are claiming is '
                + '<a class="indigo--text">'
                + '$' + demand_amount.toLocaleString("en-US")
                + '</a>' + '.'
            */

            /*
            const recommended_product = getStateMgmtStore(this.$piniaInstance).get_product_start.recommended_product
            if(recommended_product == EnumProduct.DemandLetter.type){
                //html = html + getItalicizedText(getIndigoBoldText('Demand Letter'))
            } else if (recommended_product == EnumProduct.AgreementLetter.type) {
                //html = html + getItalicizedText(getIndigoBoldText('Mutual Agreement'))
            } else if (recommended_product == EnumProduct.CourtPapers.type) {
                //html = html + getItalicizedText(getIndigoBoldText('CaseMate'))
            } else if (recommended_product == EnumProduct.CasePacket.type) {
                //html = html + getItalicizedText(getIndigoBoldText('BriefEase'))
            } else {
                console.error("unknown recommended_product = ", recommended_product)
            }
            */

            /*
            const html =
                'Here are our services.'
                + '<br><br>'
                + 'Based on what you shared, we think our '
                + '<a class="indigo--text">' + recommended_product + '</a>'
                + ' can help you.'
                + '<br><br>'
                + 'Explore all services below too:'
            */

            return html
        },
    },
    //////////////////////////////////////////////////////
    ////////////////////// FUNCTIONS /////////////////////
    //////////////////////////////////////////////////////
    methods: {
        load_ux_record(
            populate_ui,
            relogin
        ){
            console.log("load_ux_record()")
            Bugsnag.leaveBreadcrumb(
                "loading firebase database"
            )

            const state_mgmt_store = getStateMgmtStore(this.$piniaInstance)

            const databaseReference = ref(
                this.$firebaseDatabase_ux,
                //'user_id/' + this.$firebaseAuthInstance.currentUser.uid
                //'user_id/' + getStateMgmtStore(this.$piniaInstance).get_auth_user_id
                'user_id/' + state_mgmt_store.get_auth_user_id
            )


            // load firebase data once...
            // ...and save values to state mgmt store
            get(
                child(
                    databaseReference,
                    '/' // this is RELATIVE-PATH
                    //'user_id/' + firebaseAuthInstance.currentUser.uid
                    //'user_id/' + getStateMgmtStore().get_auth_user_id
                )
            ).then((result) => {
                console.log("Firebase Database Loaded")
                //console.log(result)
                Bugsnag.leaveBreadcrumb(
                    "loaded firebase database"
                )

                if (result.exists()) {
                    //console.log("inside load Firebase - EXISTS")
                    //console.log(result.val());

                    const result_dict = result.val()
                    console.log("-=-=-=-=-=-=-=-=-=-")
                    console.log("result_dict = ")
                    console.warn(result_dict)
                    console.log("-=-=-=-=-=-=-=-=-=-")
                    //example: result_dict.primary_case

                    // update state mgmt store
                    if (result_dict.product_start != null) {
                        state_mgmt_store.set_product_start_from_cloud(result_dict.product_start)
                    }
                    if (result_dict.product_demand_letter != null) {
                        state_mgmt_store.set_product_demand_letter_from_cloud(result_dict.product_demand_letter)
                    }
                    if (result_dict.product_agreement_letter != null) {
                        state_mgmt_store.set_product_agreement_letter_from_cloud(result_dict.product_agreement_letter)
                    }

                    //console.log("firebase database loading completed")
                    console.log("calling populate_ui() from load_ux_record()")

                    return populate_ui()

                } else {
                    console.error("inside load Firebase - NO DATA")
                    console.error("FMF_INCIDENT")
                    Bugsnag.notify("Firebase Loaded But No Data")

                    fmf_error_events(
                        "_error",
                        "firebase",
                        {
                            firebase: "no data loaded"
                        }
                    )  

                    // ask user to login again
                    return relogin()
                }
            }).catch((error) => {
                console.error("Error Loading Firebase Database")
                console.error(error);   // Error: Permission denied
                console.error("FMF_INCIDENT")
                Bugsnag.notify(error)

                fmf_error_events(
                    "_error",
                    "firebase",
                    {
                        firebase: error
                    }
                )                  

                // ask user to login again
                return relogin()
            });
            // keep empty
        },
        relogin(){
            // relogin is needed

            console.warn("relogin")

            // no need to flip "this.is_card_loading" status
            // flipping status causes rendering issue anyway
            //this.is_card_loading = false

            this.$router.push(RoutesMap.LOGIN.path)
        },
        populate_ui(){
            //console.log("populate_ui()")
            //console.log("is_card_loading = ", this.is_card_loading)

            const store = getStateMgmtStore(this.$piniaInstance)

            this.JourneyCard_DemandLetter_ViewModel["cover_photo"] = "cover_photo_Demand_Letter.png"
            this.JourneyCard_DemandLetter_ViewModel["title_html_text"] = getSmallText("LetterGenuis")+"<br>"+"Demand Letter"
            this.JourneyCard_DemandLetter_ViewModel["subtitle_html_text"] =
                    "Customize your Demand Letter with just a few clicks to resolve your disputes out of court."


            ///////////////////////////////////////////////////////////////////////////////////////////
            ///////////////////////////////////////////////////////////////////////////////////////////
            ///////////////////////////////////////////////////////////////////////////////////////////


            let status_demand_letter;
            if (store.get_product_demand_letter.has_started){
                //if (isValidString(store.get_product_demand_letter.paid_storage_path)) {
                if (isValidString(store.get_product_demand_letter.paid_timestamp_utc_iso)) {
                    status_demand_letter = EnumJourneyCardStepStatus.PAID.type
                } else if (isValidString(store.get_product_demand_letter.preview_and_upgrade_link)) {
                    status_demand_letter = EnumJourneyCardStepStatus.UNPAID.type
                } else {
                    status_demand_letter = EnumJourneyCardStepStatus.IN_PROGRESS.type
                }
            } else {
                status_demand_letter = EnumJourneyCardStepStatus.NEW.type
            }
            ///////////////////////////////////////////////////////////////////////////////////////////
            //status_demand_letter = EnumJourneyCardStepStatus.NEW.type
            //status_demand_letter = EnumJourneyCardStepStatus.IN_PROGRESS.type
            //status_demand_letter = EnumJourneyCardStepStatus.UNPAID.type
            //status_demand_letter = EnumJourneyCardStepStatus.PAID.type
            ///////////////////////////////////////////////////////////////////////////////////////////
            if (status_demand_letter == EnumJourneyCardStepStatus.NEW.type){
                // first arrive
                this.JourneyCard_DemandLetter_ViewModel["step_status"] = EnumJourneyCardStepStatus.NEW.type
                this.JourneyCard_DemandLetter_ViewModel["step_status_icon"] = "mdi-pencil-circle"
                this.JourneyCard_DemandLetter_ViewModel["step_status_icon_text"] = "10 min"
                this.JourneyCard_DemandLetter_ViewModel["journey_card_step_status_style"] =
                {
                    background: "#2979ff", // status chip background blue
                    color: 'white' // text color
                }
                this.JourneyCard_DemandLetter_ViewModel["left_button_text"] = "Start Now"
                this.JourneyCard_DemandLetter_ViewModel["left_button_action"] = EnumJourneyCardButtonAction.DemandLetter.CONTINUE
                this.JourneyCard_DemandLetter_ViewModel["right_button_text"] = null
                this.JourneyCard_DemandLetter_ViewModel["right_button_action"] = null
            } else if (status_demand_letter == EnumJourneyCardStepStatus.IN_PROGRESS.type) {
                // started, but not done with letter
                this.JourneyCard_DemandLetter_ViewModel["step_status"] = EnumJourneyCardStepStatus.IN_PROGRESS.type
                this.JourneyCard_DemandLetter_ViewModel["step_status_icon"] = "mdi-pencil-circle"
                this.JourneyCard_DemandLetter_ViewModel["step_status_icon_text"] = "10 min"
                this.JourneyCard_DemandLetter_ViewModel["left_button_text"] = "Continue"
                this.JourneyCard_DemandLetter_ViewModel["journey_card_step_status_style"] =
                {
                    background: "#2979ff", // status chip background blue
                    color: 'white' // text color
                }
                this.JourneyCard_DemandLetter_ViewModel["left_button_action"] = EnumJourneyCardButtonAction.DemandLetter.CONTINUE
                this.JourneyCard_DemandLetter_ViewModel["right_button_text"] = null
                this.JourneyCard_DemandLetter_ViewModel["right_button_action"] = null
            } else if (status_demand_letter == EnumJourneyCardStepStatus.UNPAID.type) {
                // done with letter - FREE
                this.JourneyCard_DemandLetter_ViewModel["step_status"] = EnumJourneyCardStepStatus.UNPAID.type
                this.JourneyCard_DemandLetter_ViewModel["step_status_icon"] = "mdi-clock"
                this.JourneyCard_DemandLetter_ViewModel["step_status_icon_text"] = "Pending"
                this.JourneyCard_DemandLetter_ViewModel["journey_card_step_status_style"] =
                {
                    background: "#2979ff", // status chip background blue
                    color: 'white' // text color
                }
                this.JourneyCard_DemandLetter_ViewModel["left_button_text"] = "View"
                this.JourneyCard_DemandLetter_ViewModel["left_button_action"] = EnumJourneyCardButtonAction.DemandLetter.VIEW
                this.JourneyCard_DemandLetter_ViewModel["right_button_text"] = "Send Letter"
                this.JourneyCard_DemandLetter_ViewModel["right_button_action"] = EnumJourneyCardButtonAction.DemandLetter.UPGRADE
            } else if (status_demand_letter == EnumJourneyCardStepStatus.PAID.type) {
                // done with letter and paid
                this.JourneyCard_DemandLetter_ViewModel["step_status"] = EnumJourneyCardStepStatus.PAID.type
                this.JourneyCard_DemandLetter_ViewModel["step_status_icon"] = "mdi-check-circle"
                this.JourneyCard_DemandLetter_ViewModel["step_status_icon_text"] = "100%"
                this.JourneyCard_DemandLetter_ViewModel["journey_card_step_status_style"] =
                {
                    background: "#4caf50", // status chip background green
                    color: 'white' // text color
                }
                this.JourneyCard_DemandLetter_ViewModel["left_button_text"] = "View"
                this.JourneyCard_DemandLetter_ViewModel["left_button_action"] = EnumJourneyCardButtonAction.DemandLetter.VIEW
                this.JourneyCard_DemandLetter_ViewModel["right_button_text"] = "Track Status"
                this.JourneyCard_DemandLetter_ViewModel["right_button_action"] = EnumJourneyCardButtonAction.DemandLetter.TRACK
            }

            ///////////////////////////////////////////////////////////////////////////////////////////
            ///////////////////////////////////////////////////////////////////////////////////////////
            ///////////////////////////////////////////////////////////////////////////////////////////

            let status_agreement_letter;
            if (store.get_product_agreement_letter.has_started) {
                //if (isValidString(store.get_product_agreement_letter.paid_storage_path)) {
                if (isValidString(store.get_product_agreement_letter.paid_timestamp_utc_iso)) {
                    status_agreement_letter = EnumJourneyCardStepStatus.PAID.type
                } else if (isValidString(store.get_product_agreement_letter.preview_and_upgrade_link)) {
                    status_agreement_letter = EnumJourneyCardStepStatus.UNPAID.type
                } else {
                    status_agreement_letter = EnumJourneyCardStepStatus.IN_PROGRESS.type
                }
            } else {
                status_agreement_letter = EnumJourneyCardStepStatus.NEW.type
            }
            ///////////////////////////////////////////////////////////////////////////////////////////
            //status_agreement_letter = EnumJourneyCardStepStatus.NEW.type
            //status_agreement_letter = EnumJourneyCardStepStatus.IN_PROGRESS.type
            //status_agreement_letter = EnumJourneyCardStepStatus.UNPAID.type
            //status_agreement_letter = EnumJourneyCardStepStatus.PAID.type
            ///////////////////////////////////////////////////////////////////////////////////////////
            this.JourneyCard_AgreementLetter_ViewModel["cover_photo"] = "cover_photo_Mutual_Agreement.png"
            this.JourneyCard_AgreementLetter_ViewModel["title_html_text"] = getSmallText("LetterGenuis") + "<br>"+"Mutual Agreement"
            this.JourneyCard_AgreementLetter_ViewModel["subtitle_html_text"] =
                    "Customize your Mutual Agreement with just a few clicks to settle your disputes out of court."
            ///////////////////////////////////////////////////////////////////////////////////////////
            ///////////////////////////////////////////////////////////////////////////////////////////
            if (status_agreement_letter == EnumJourneyCardStepStatus.NEW.type) {
                // first arrive
                this.JourneyCard_AgreementLetter_ViewModel["step_status"] = EnumJourneyCardStepStatus.NEW.type
                this.JourneyCard_AgreementLetter_ViewModel["step_status_icon"] = "mdi-pencil-circle"
                this.JourneyCard_AgreementLetter_ViewModel["step_status_icon_text"] = "10 min"
                this.JourneyCard_AgreementLetter_ViewModel["journey_card_step_status_style"] =
                {
                    background: "#2979ff", // status chip background blue
                    color: 'white' // text color
                }
                this.JourneyCard_AgreementLetter_ViewModel["left_button_text"] = "Start"
                this.JourneyCard_AgreementLetter_ViewModel["left_button_action"] = EnumJourneyCardButtonAction.AgreementLetter.CONTINUE
                this.JourneyCard_AgreementLetter_ViewModel["right_button_text"] = null
                this.JourneyCard_AgreementLetter_ViewModel["right_button_action"] = null
            } else if (status_agreement_letter == EnumJourneyCardStepStatus.IN_PROGRESS.type) {
                // started, but not done with letter
                this.JourneyCard_AgreementLetter_ViewModel["step_status"] = EnumJourneyCardStepStatus.IN_PROGRESS.type
                this.JourneyCard_AgreementLetter_ViewModel["step_status_icon"] = "mdi-pencil-circle"
                this.JourneyCard_AgreementLetter_ViewModel["step_status_icon_text"] = "10 min"
                this.JourneyCard_AgreementLetter_ViewModel["left_button_text"] = "Continue"
                this.JourneyCard_AgreementLetter_ViewModel["journey_card_step_status_style"] =
                {
                    background: "#2979ff", // status chip background blue
                    color: 'white' // text color
                }
                this.JourneyCard_AgreementLetter_ViewModel["left_button_action"] = EnumJourneyCardButtonAction.AgreementLetter.CONTINUE
                this.JourneyCard_AgreementLetter_ViewModel["right_button_text"] = null
                this.JourneyCard_AgreementLetter_ViewModel["right_button_action"] = null
            } else if (status_agreement_letter == EnumJourneyCardStepStatus.UNPAID.type) {
                // done with letter - FREE
                this.JourneyCard_AgreementLetter_ViewModel["step_status"] = EnumJourneyCardStepStatus.UNPAID.type
                this.JourneyCard_AgreementLetter_ViewModel["step_status_icon"] = "mdi-clock"
                this.JourneyCard_AgreementLetter_ViewModel["step_status_icon_text"] = "Pending"
                this.JourneyCard_AgreementLetter_ViewModel["journey_card_step_status_style"] =
                {
                    background: "#2979ff", // status chip background blue
                    color: 'white' // text color
                }
                this.JourneyCard_AgreementLetter_ViewModel["left_button_text"] = "View"
                this.JourneyCard_AgreementLetter_ViewModel["left_button_action"] = EnumJourneyCardButtonAction.AgreementLetter.VIEW
                this.JourneyCard_AgreementLetter_ViewModel["right_button_text"] = "E-Sign"
                this.JourneyCard_AgreementLetter_ViewModel["right_button_action"] = EnumJourneyCardButtonAction.AgreementLetter.UPGRADE
            } else if (status_agreement_letter == EnumJourneyCardStepStatus.PAID.type) {
                // done with letter and paid
                this.JourneyCard_AgreementLetter_ViewModel["step_status"] = EnumJourneyCardStepStatus.PAID.type
                this.JourneyCard_AgreementLetter_ViewModel["step_status_icon"] = "mdi-check-circle"
                this.JourneyCard_AgreementLetter_ViewModel["step_status_icon_text"] = "100%"
                this.JourneyCard_AgreementLetter_ViewModel["journey_card_step_status_style"] =
                {
                    background: "#4caf50", // status chip background green
                    color: 'white' // text color
                }
                this.JourneyCard_AgreementLetter_ViewModel["left_button_text"] = "View"
                this.JourneyCard_AgreementLetter_ViewModel["left_button_action"] = EnumJourneyCardButtonAction.AgreementLetter.VIEW
                this.JourneyCard_AgreementLetter_ViewModel["right_button_text"] = "Track Status"
                this.JourneyCard_AgreementLetter_ViewModel["right_button_action"] = EnumJourneyCardButtonAction.AgreementLetter.TRACK
            }


            ///////////////////////////////////////////////////////////////////////////////////////////
            ///////////////////////////////////////////////////////////////////////////////////////////
            ///////////////////////////////////////////////////////////////////////////////////////////

            let status_court_papers;
            ///////////////////////////////////////////////////////////////////////////////////////////
            status_court_papers = EnumJourneyCardStepStatus.NEW.type
            //status_court_papers = EnumJourneyCardStepStatus.IN_PROGRESS.type
            //status_court_papers = EnumJourneyCardStepStatus.UNPAID.type
            //status_court_papers = EnumJourneyCardStepStatus.PAID.type
            ///////////////////////////////////////////////////////////////////////////////////////////
            this.JourneyCard_CourtPapers_ViewModel["cover_photo"] = "cover_photo_Small_Claims_Filing.png"
            this.JourneyCard_CourtPapers_ViewModel["title_html_text"] = "CaseMate"
            this.JourneyCard_CourtPapers_ViewModel["subtitle_html_text"] =
                    "A fast and simple way to start your Small Claims filing to get the money you are owed."
            ///////////////////////////////////////////////////////////////////////////////////////////
            ///////////////////////////////////////////////////////////////////////////////////////////
            if (status_court_papers == EnumJourneyCardStepStatus.NEW.type) {
                // first arrive
                this.JourneyCard_CourtPapers_ViewModel["step_status"] = EnumJourneyCardStepStatus.NEW.type
                this.JourneyCard_CourtPapers_ViewModel["step_status_icon"] = "mdi-pencil-circle"
                this.JourneyCard_CourtPapers_ViewModel["step_status_icon_text"] = "20 min"
                this.JourneyCard_CourtPapers_ViewModel["journey_card_step_status_style"] =
                {
                    background: "#2979ff", // status chip background blue
                    color: 'white' // text color
                }
                this.JourneyCard_CourtPapers_ViewModel["left_button_text"] = "Start"
                this.JourneyCard_CourtPapers_ViewModel["left_button_action"] = EnumJourneyCardButtonAction.CourtPapers.CONTINUE
                this.JourneyCard_CourtPapers_ViewModel["right_button_text"] = null
                this.JourneyCard_CourtPapers_ViewModel["right_button_action"] = null
            } else if (status_court_papers == EnumJourneyCardStepStatus.IN_PROGRESS.type) {
                // started, but not done with letter
                this.JourneyCard_CourtPapers_ViewModel["step_status"] = EnumJourneyCardStepStatus.IN_PROGRESS.type
                this.JourneyCard_CourtPapers_ViewModel["step_status_icon"] = "mdi-pencil-circle"
                this.JourneyCard_CourtPapers_ViewModel["step_status_icon_text"] = "20 min"
                this.JourneyCard_CourtPapers_ViewModel["left_button_text"] = "Continue"
                this.JourneyCard_CourtPapers_ViewModel["journey_card_step_status_style"] =
                {
                    background: "#2979ff", // status chip background blue
                    color: 'white' // text color
                }
                this.JourneyCard_CourtPapers_ViewModel["left_button_action"] = EnumJourneyCardButtonAction.CourtPapers.CONTINUE
                this.JourneyCard_CourtPapers_ViewModel["right_button_text"] = null
                this.JourneyCard_CourtPapers_ViewModel["right_button_action"] = null
            } else if (status_court_papers == EnumJourneyCardStepStatus.UNPAID.type) {
                // done with letter - FREE
                this.JourneyCard_CourtPapers_ViewModel["step_status"] = EnumJourneyCardStepStatus.UNPAID.type
                this.JourneyCard_CourtPapers_ViewModel["step_status_icon"] = "mdi-clock"
                this.JourneyCard_CourtPapers_ViewModel["step_status_icon_text"] = "Pending"
                this.JourneyCard_CourtPapers_ViewModel["journey_card_step_status_style"] =
                {
                    background: "#2979ff", // status chip background blue
                    color: 'white' // text color
                }
                this.JourneyCard_CourtPapers_ViewModel["left_button_text"] = "View"
                //this.JourneyCard_CourtPapers_ViewModel["left_button_action"] = EnumJourneyCardButtonAction.CourtPapers.VIEW
                this.JourneyCard_CourtPapers_ViewModel["right_button_text"] = "Mail Now"
                //this.JourneyCard_CourtPapers_ViewModel["right_button_action"] = EnumJourneyCardButtonAction.CourtPapers.UPGRADE
            } else if (status_court_papers == EnumJourneyCardStepStatus.PAID.type) {
                // done with letter and paid
                this.JourneyCard_CourtPapers_ViewModel["step_status"] = EnumJourneyCardStepStatus.PAID.type
                this.JourneyCard_CourtPapers_ViewModel["step_status_icon"] = "mdi-check-circle"
                this.JourneyCard_CourtPapers_ViewModel["step_status_icon_text"] = "100%"
                this.JourneyCard_CourtPapers_ViewModel["journey_card_step_status_style"] =
                {
                    background: "#4caf50", // status chip background green
                    color: 'white' // text color
                }
                this.JourneyCard_CourtPapers_ViewModel["left_button_text"] = "View"
                //this.JourneyCard_CourtPapers_ViewModel["left_button_action"] = EnumJourneyCardButtonAction.DemandLetter.VIEW
                this.JourneyCard_CourtPapers_ViewModel["right_button_text"] = "Track Status"
                //this.JourneyCard_CourtPapers_ViewModel["right_button_action"] = EnumJourneyCardButtonAction.DemandLetter.TRACK
            }

            ///////////////////////////////////////////////////////////////////////////////////////////
            ///////////////////////////////////////////////////////////////////////////////////////////
            ///////////////////////////////////////////////////////////////////////////////////////////

            let status_case_packet;
            ///////////////////////////////////////////////////////////////////////////////////////////
            status_case_packet = EnumJourneyCardStepStatus.NEW.type
            //status_case_packet = EnumJourneyCardStepStatus.IN_PROGRESS.type
            //status_case_packet = EnumJourneyCardStepStatus.UNPAID.type
            //status_case_packet = EnumJourneyCardStepStatus.PAID.type
            ///////////////////////////////////////////////////////////////////////////////////////////

            this.JourneyCard_CasePacket_ViewModel["cover_photo"] = "cover_photo_Small_Claims_Brief.png"
            this.JourneyCard_CasePacket_ViewModel["title_html_text"] = "BriefEase"
            this.JourneyCard_CasePacket_ViewModel["subtitle_html_text"] =
                    "Get your Small Claims case ready for court with ease and confidence in just 3 simple steps."

            if (status_case_packet == EnumJourneyCardStepStatus.NEW.type) {
                // first arrive
                this.JourneyCard_CasePacket_ViewModel["step_status"] = EnumJourneyCardStepStatus.NEW.type
                this.JourneyCard_CasePacket_ViewModel["step_status_icon"] = "mdi-pencil-circle"
                this.JourneyCard_CasePacket_ViewModel["step_status_icon_text"] = "20 min"
                this.JourneyCard_CasePacket_ViewModel["journey_card_step_status_style"] =
                {
                    background: "#2979ff", // status chip background blue
                    color: 'white' // text color
                }
                this.JourneyCard_CasePacket_ViewModel["left_button_text"] = "Start"
                this.JourneyCard_CasePacket_ViewModel["left_button_action"] = EnumJourneyCardButtonAction.CasePacket.CONTINUE
                this.JourneyCard_CasePacket_ViewModel["right_button_text"] = null
                this.JourneyCard_CasePacket_ViewModel["right_button_action"] = null
            } else if (status_case_packet == EnumJourneyCardStepStatus.IN_PROGRESS.type) {
                // started, but not done with letter
                this.JourneyCard_CasePacket_ViewModel["step_status"] = EnumJourneyCardStepStatus.IN_PROGRESS.type
                this.JourneyCard_CasePacket_ViewModel["step_status_icon"] = "mdi-pencil-circle"
                this.JourneyCard_CasePacket_ViewModel["step_status_icon_text"] = "20 min"
                this.JourneyCard_CasePacket_ViewModel["left_button_text"] = "Continue"
                this.JourneyCard_CasePacket_ViewModel["journey_card_step_status_style"] =
                {
                    background: "#2979ff", // status chip background blue
                    color: 'white' // text color
                }
                this.JourneyCard_CasePacket_ViewModel["left_button_action"] = EnumJourneyCardButtonAction.CasePacket.CONTINUE
                this.JourneyCard_CasePacket_ViewModel["right_button_text"] = null
                this.JourneyCard_CasePacket_ViewModel["right_button_action"] = null
            } else if (status_case_packet == EnumJourneyCardStepStatus.UNPAID.type) {
                // done with letter - FREE
                this.JourneyCard_CasePacket_ViewModel["step_status"] = EnumJourneyCardStepStatus.UNPAID.type
                this.JourneyCard_CasePacket_ViewModel["step_status_icon"] = "mdi-clock"
                this.JourneyCard_CasePacket_ViewModel["step_status_icon_text"] = "Pending"
                this.JourneyCard_CasePacket_ViewModel["journey_card_step_status_style"] =
                {
                    background: "#2979ff", // status chip background blue
                    color: 'white' // text color
                }
                this.JourneyCard_CasePacket_ViewModel["left_button_text"] = "View"
                //this.JourneyCard_CasePacket_ViewModel["left_button_action"] = EnumJourneyCardButtonAction.CasePacket.VIEW
                this.JourneyCard_CasePacket_ViewModel["right_button_text"] = "Mail Now"
                //this.JourneyCard_CasePacket_ViewModel["right_button_action"] = EnumJourneyCardButtonAction.CasePacket.UPGRADE
            } else if (status_case_packet == EnumJourneyCardStepStatus.PAID.type) {
                // done with letter and paid
                this.JourneyCard_CasePacket_ViewModel["step_status"] = EnumJourneyCardStepStatus.PAID.type
                this.JourneyCard_CasePacket_ViewModel["step_status_icon"] = "mdi-check-circle"
                this.JourneyCard_CasePacket_ViewModel["step_status_icon_text"] = "100%"
                this.JourneyCard_CasePacket_ViewModel["journey_card_step_status_style"] =
                {
                    background: "#4caf50", // status chip background green
                    color: 'white' // text color
                }
                this.JourneyCard_CasePacket_ViewModel["left_button_text"] = "View"
                //this.JourneyCard_CasePacket_ViewModel["left_button_action"] = EnumJourneyCardButtonAction.CasePacket.VIEW
                this.JourneyCard_CasePacket_ViewModel["right_button_text"] = "Track Order"
                //this.JourneyCard_CasePacket_ViewModel["right_button_action"] = EnumJourneyCardButtonAction.CasePacket.TRACK
            }

            ///////////////////////////////////////////
            //////////////// IMPORTANT ////////////////
            ///////////////////////////////////////////
            console.log("change visual state after UI values are set")
            this.is_card_loading = false

        },
        change_setup(){
            console.log("change_setup()")
            fmf_event(
                "Clicked_StartNewCase",
                {
                    //result: recommended_product,
                }
            )

            this.$router.push(RoutesMap.PRODUCT_START.path)

            // DEV CODE
            //this.onCheckAuthStatus()
        },
        onProceed_JourneyPage_PopUp(){
            console.log("onProceed_JourneyPage_PopUp()")

            this.SHOW_JourneyPage_PopUp = false

            this.JourneyPage_PopUp_ViewModel["popup_title_html_text"] = null
            this.JourneyPage_PopUp_ViewModel["popup_content_html_text"] = null
            this.JourneyPage_PopUp_ViewModel["popup_main_button_text"] = "Close"

        },
        onProceed_JourneyCard_DemandLetter(action) {

            const metadata = {
                action: action,
                //token_valid: getStateMgmtStore(this.$piniaInstance).get_is_auth_token_valid
            }
            Bugsnag.leaveBreadcrumb(
                "JourneyCard_DemandLetter",
                metadata
            )

            /*
            if (!getStateMgmtStore(this.$piniaInstance).get_is_auth_token_valid) {
                //this.$firebaseAuthInstance.currentUser.getIdToken()
                //const token = await user.getIdToken()
                this.$router.push(RoutesMap.LOGIN.path)
                return
            }
            */

            console.log("onProceed_JourneyCard_DemandLetter()")
            console.log("action = ", action)
            
            
            getStateMgmtStore(this.$piniaInstance).set_journey_progress_selected_product(
                EnumProduct.DemandLetter.type
            )

            if (action == EnumJourneyCardButtonAction.DemandLetter.CONTINUE){
                this.$router.push(RoutesMap.PRODUCT_DEMAND_LETTER.path)
            } else if (action == EnumJourneyCardButtonAction.DemandLetter.UPGRADE) {

                /*
                const full_firebase_url = getStateMgmtStore(this.$piniaInstance).get_product_demand_letter.preview_storage_path
                // Example
                // const full_firebase_url = 'https://firebasestorage.googleapis.com/v0/b/dev-fillmeforms.appspot.com/o/Preview%2FDemandLetter%2FFillMeForms-DemandLetter-nWbGi-2023-02-14T23-16-47.pdf?alt=media&token=9cf5caec-e4f7-430c-acfc-01080efa8071'
                //console.warn("full_firebase_url = ", full_firebase_url)

                const redirectUrl = getRedirectUrl(
                    EnumProduct.DemandLetter.type,
                    full_firebase_url
                )                

                // Example:
                // const redirectUrl = 'https://fillmeforms.com/play?result=Preview%2FDemandLetter%2FFillMeForms-DemandLetter-nWbGi-2023-02-14T23-16-47.pdf?alt=media&token=9cf5caec-e4f7-430c-acfc-01080efa8071'
                //console.warn("redirectUrl = ", redirectUrl)
                */


                const redirectUrl = getStateMgmtStore(this.$piniaInstance).get_product_demand_letter.preview_and_upgrade_link

                if (isValidString(redirectUrl)) {
                    //window.location = redirectUrl

                    // use replace() so that user clicking back don't land here
                    if (this.$currentEnv == env.PROD) {
                        // go to next page here, no need to REPLACE
                        window.location = redirectUrl
                    } else {
                        console.warn("non-Prod Environment = ")
                        console.warn("redirectUrl = ", redirectUrl)
                        // may comment in local
                        window.location = redirectUrl
                    }

                } else {
                    console.error("invalid redirectUrl")
                    console.error(redirectUrl)
                    console.error("FMF_INCIDENT")
                    Bugsnag.notify("invalid string redirectUrl")

                    fmf_error_events(
                        "_error",
                        "url",
                        {
                            redirect_url: "invalid demand letter redirectUrl"
                        }
                    )
                }
                // nothing here

            } else if (action == EnumJourneyCardButtonAction.DemandLetter.VIEW) {
                //go to documents
                this.$router.push(RoutesMap.DOCUMENTS.path)
            } else if (action == EnumJourneyCardButtonAction.DemandLetter.TRACK) {
                //go to tracking
                const paid_timestamp = getStateMgmtStore(this.$piniaInstance).product_demand_letter.paid_timestamp_utc_iso
                const paid_document_name = getStateMgmtStore(this.$piniaInstance).product_demand_letter.paid_document_name
                const paid_mail_tracking_link = getStateMgmtStore(this.$piniaInstance).product_demand_letter.paid_mail_tracking_link

                const has_complete_tracking_info = isValidString(paid_timestamp) && isValidString(paid_document_name) && isValidString(paid_mail_tracking_link)
                //const has_complete_tracking_info = true
                //const has_complete_tracking_info = false

                if(has_complete_tracking_info){
                    // show popup with more info
                    this.JourneyPage_PopUp_ViewModel["popup_title_html_text"] = "Your Demand Letter is Mailed!"

                    this.JourneyPage_PopUp_ViewModel["popup_content_html_text"] =
                                        "<br>"
                                        + "Your Demand Letter has been successfully mailed to the recipient! "
                                        + "<br><br>"
                                        + "A confirmation email with the tracking number is sent to you. You can also view and download the final version of your Demand Letter in your account anytime."
                                        + "<br><br>"
                                        + "Thank you for choosing "
                                        + getIndigoBoldText("FillMeForms")
                                        +" to help you resolve your legal disputes. If you have any questions, please don't hesitate to contact us."

                    this.JourneyPage_PopUp_ViewModel["popup_main_button_text"] = "Close"

                } else {
                    // show generic pop-up
                    this.JourneyPage_PopUp_ViewModel["popup_title_html_text"] = "Getting Ready..."

                    this.JourneyPage_PopUp_ViewModel["popup_content_html_text"] =
                                        "<br>"
                                        + "Great news! Your Demand Letter is in the works!"
                                        + "<br><br>"
                                        + "We'll send you an email with a link to electronically sign your Demand Letter. Once that's done, sit back and let us do the mailing for you."
                                        + "<br><br>"
                                        + "Thank you for choosing "
                                        + getIndigoBoldText("FillMeForms")
                                        +" to help you resolve your legal disputes. If you have any questions, please don't hesitate to contact us."
                    this.JourneyPage_PopUp_ViewModel["popup_main_button_text"] = "Close"
                }
                // do this at the END, after view model is set
                // because the WATCHER on child is by the SHOW flag
                this.SHOW_JourneyPage_PopUp = true
            }

        },
        onProceed_JourneyCard_AgreementLetter(action){
            const metadata = {
                action: action,
                //token_valid: getStateMgmtStore(this.$piniaInstance).get_is_auth_token_valid
            }
            Bugsnag.leaveBreadcrumb(
                "JourneyCard_AgreementLetter",
                metadata
            )

            /*
            if (!getStateMgmtStore(this.$piniaInstance).get_is_auth_token_valid) {
                this.$router.push(RoutesMap.LOGIN.path)
                return
            }
            */

            console.log("onProceed_JourneyCard_AgreementLetter()")
            console.log("action = ", action)

            getStateMgmtStore(this.$piniaInstance).set_journey_progress_selected_product(
                EnumProduct.AgreementLetter.type
            )

            if (action == EnumJourneyCardButtonAction.AgreementLetter.CONTINUE) {
                this.$router.push(RoutesMap.PRODUCT_AGREEMENT_LETTER.path)
            } else if (action == EnumJourneyCardButtonAction.AgreementLetter.UPGRADE) {

                /*
                const full_firebase_url = getStateMgmtStore(this.$piniaInstance).get_product_agreement_letter.preview_storage_path
                // Example
                // const full_firebase_url = 'https://firebasestorage.googleapis.com/v0/b/dev-fillmeforms.appspot.com/o/Preview%2FDemandLetter%2FFillMeForms-DemandLetter-nWbGi-2023-02-14T23-16-47.pdf?alt=media&token=9cf5caec-e4f7-430c-acfc-01080efa8071'
                //console.warn("full_firebase_url = ", full_firebase_url)

                const redirectUrl = getRedirectUrl(
                    EnumProduct.AgreementLetter.type,
                    full_firebase_url
                )
                // Example:
                // const redirectUrl = 'https://fillmeforms.com/play?result=Preview%2FDemandLetter%2FFillMeForms-DemandLetter-nWbGi-2023-02-14T23-16-47.pdf?alt=media&token=9cf5caec-e4f7-430c-acfc-01080efa8071'
                //console.warn("redirectUrl = ", redirectUrl)
                */


                const redirectUrl = getStateMgmtStore(this.$piniaInstance).get_product_agreement_letter.preview_and_upgrade_link

                if (isValidString(redirectUrl)) {
                    //window.location = redirectUrl

                    // use replace() so that user clicking back don't land here
                    if (this.$currentEnv == env.PROD) {
                        // go to next page here, no need to REPLACE
                        window.location = redirectUrl
                    } else {
                        console.warn("non-Prod Environment = ")
                        console.warn("redirectUrl = ", redirectUrl)
                        // may comment in local
                        window.location = redirectUrl
                    }

                } else {
                    console.error("invalid redirectUrl")
                    console.error(redirectUrl)
                    console.error("FMF_INCIDENT")
                    Bugsnag.notify("invalid string redirectUrl")

                    fmf_error_events(
                        "_error",
                        "url",
                        {
                            redirect_url: "invalid agreement letter redirectUrl"
                        }
                    )
                }                
                // nothing here

            } else if (action == EnumJourneyCardButtonAction.AgreementLetter.VIEW) {
                //go to documents
                this.$router.push(RoutesMap.DOCUMENTS.path)
            } else if (action == EnumJourneyCardButtonAction.AgreementLetter.TRACK) {
                //go to tracking

                const paid_timestamp = getStateMgmtStore(this.$piniaInstance).product_agreement_letter.paid_timestamp_utc_iso
                //const paid_storage_path = getStateMgmtStore(this.$piniaInstance).product_agreement_letter.paid_storage_path
                const paid_document_name = getStateMgmtStore(this.$piniaInstance).product_agreement_letter.paid_document_name

                //const has_complete_tracking_info = isValidString(paid_timestamp) && isValidString(paid_storage_path) && isValidString(paid_document_name)
                const has_complete_tracking_info = isValidString(paid_timestamp) && isValidString(paid_document_name)
                //const has_complete_tracking_info = true
                //const has_complete_tracking_info = false

                if (has_complete_tracking_info) {
                    // show popup with more info
                    this.JourneyPage_PopUp_ViewModel["popup_title_html_text"] = "Your Mutual Agreement is Completed!"

                    this.JourneyPage_PopUp_ViewModel["popup_content_html_text"] =
                        "<br>"
                        + "Your Mutual Agreement has been successfully signed by both parties! "
                        + "<br><br>"
                        + "You should have already received an email confirmation with a copy of the agreement attached for your records. You can also view and download the final version of your Mutual Agreement in your account anytime."
                        + "<br><br>"
                        + "Thank you for choosing "
                        + getIndigoBoldText("FillMeForms")
                        + " to help you settle your legal disputes. If you have any questions, please don't hesitate to contact us."

                    this.JourneyPage_PopUp_ViewModel["popup_main_button_text"] = "Close"

                } else {
                    // show generic pop-up
                    this.JourneyPage_PopUp_ViewModel["popup_title_html_text"] = "Getting Ready..."

                    this.JourneyPage_PopUp_ViewModel["popup_content_html_text"] =
                        "<br>"
                        + "Great news! We are preparing you Mutual Agreement for electronic signature."
                        + "<br><br>"
                        + "We'll send you an email shortly to ask for the email addresses of both parties. Once we receive the email addresses, we'll send an email with a link for both parties to eletronically sign the Mutual Agreement."
                        + "<br><br>"
                        + "Thank you for choosing "
                        + getIndigoBoldText("FillMeForms")
                        + " to help you settle your legal disputes. If you have any questions, please don't hesitate to contact us."



                    this.JourneyPage_PopUp_ViewModel["popup_main_button_text"] = "Close"
                }
                // do this at the END, after view model is set
                // because the WATCHER on child is by the SHOW flag
                this.SHOW_JourneyPage_PopUp = true

            }

        },
        onProceed_JourneyCard_CourtPapers(action) {

            const metadata = {
                action: action,
                //token_valid: getStateMgmtStore(this.$piniaInstance).get_is_auth_token_valid
            }
            Bugsnag.leaveBreadcrumb(
                "JourneyCard_CourtPapers",
                metadata
            )

            /*
            if (!getStateMgmtStore(this.$piniaInstance).get_is_auth_token_valid) {
                //this.$router.replace(RoutesMap.LOGIN.path)
                this.$router.push(RoutesMap.LOGIN.path)
                return
            }
            */

            console.log("onProceed_JourneyCard_CourtPapers()")
            console.log("action = ", action)

            getStateMgmtStore(this.$piniaInstance).set_journey_progress_selected_product(
                EnumProduct.CourtPapers.type
            )

            window.location = FMF_URLs.COURT_PAPERS_PAYMENT.url
            //this.$router.push(RoutesMap.PRODUCT_COURT_PAPERS.path)
            /*
            if(action == EnumJourneyCardButtonAction.CourtPapers.CONTINUE) {

            }
            */

            /*
            if (action == EnumJourneyCardButtonAction.AgreementLetter.CONTINUE) {
            } else if (action == EnumJourneyCardButtonAction.AgreementLetter.UPGRADE) {
                this.$router.push(RoutesMap.SMALL_CLAIMS_SERVICE_PREVIEW.path)
            } else if (action == EnumJourneyCardButtonAction.AgreementLetter.VIEW) {
                //go to documents
            } else if (action == EnumJourneyCardButtonAction.AgreementLetter.TRACK) {
                //go to tracking
            }
            */

        },
        onProceed_JourneyCard_CasePacket(action) {

            const metadata = {
                action: action,
                //token_valid: getStateMgmtStore(this.$piniaInstance).get_is_auth_token_valid
            }
            Bugsnag.leaveBreadcrumb(
                "JourneyCard_CasePacket",
                metadata
            )

            /*
            if (!getStateMgmtStore(this.$piniaInstance).get_is_auth_token_valid) {
                //this.$router.replace(RoutesMap.LOGIN.path)
                this.$router.push(RoutesMap.LOGIN.path)
                return
            }
            */

            console.log("onProceed_JourneyCard_CasePacket()")
            console.log("action = ", action)

            getStateMgmtStore(this.$piniaInstance).set_journey_progress_selected_product(
                EnumProduct.CasePacket.type
            )

            window.location = FMF_URLs.CASE_PACKET_PAYMENT.url
            //this.$router.push(RoutesMap.PRODUCT_PREPARE_CASE.path)

            /*
            if(action == EnumJourneyCardButtonAction.CasePacket.CONTINUE) {

            }
            */

            /*
            if (action == EnumJourneyCardButtonAction.AgreementLetter.CONTINUE) {
            } else if (action == EnumJourneyCardButtonAction.AgreementLetter.UPGRADE) {
                this.$router.push(RoutesMap.SMALL_CLAIMS_SERVICE_PREVIEW.path)
            } else if (action == EnumJourneyCardButtonAction.AgreementLetter.VIEW) {
                //go to documents
            } else if (action == EnumJourneyCardButtonAction.AgreementLetter.TRACK) {
                //go to tracking
            }
            */

        },
        onCheckAuthStatus() {
            console.log("onCheckAuthStatus()")
            fmf_CheckAuthStatus(
                this.onResolve_CheckAuthStatus,
                this.onReject_CheckAuthStatus
            )
        },
        onResolve_CheckAuthStatus(jsonResponseObject){
            console.log("onResolve_CheckAuthStatus()")
            /*
            if(jsonResponseObject != null){
                console.warn("token = ", jsonResponseObject.token)
            } else {
                console.warn("jsonResponseObject is NULL")
            }
            */
        },
        onReject_CheckAuthStatus(errorCode, errorMessage){
            console.log("onReject_CheckAuthStatus()")
        },
    },
}


</script>