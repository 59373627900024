/**
 * FillMeForms API
 * FillMeForms API Specs Used for client-server communication 
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The GenerateFileResponse model module.
 * @module model/GenerateFileResponse
 * @version 0.0.1
 */
class GenerateFileResponse {
    /**
     * Constructs a new <code>GenerateFileResponse</code>.
     * the generated file as an url and other helpful data
     * @alias module:model/GenerateFileResponse
     * @param storageBucket {String} the google cloud storage bucket of this file
     * @param fileDirectory {String} the relative file location within the cloud storage bucket
     * @param documentName {String} the customer-facing file name
     * @param lastUpdatedUtcUnix {Number} the unix timestamp in seconds when this file was last updated
     */
    constructor(storageBucket, fileDirectory, documentName, lastUpdatedUtcUnix) { 
        
        GenerateFileResponse.initialize(this, storageBucket, fileDirectory, documentName, lastUpdatedUtcUnix);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, storageBucket, fileDirectory, documentName, lastUpdatedUtcUnix) { 
        obj['storage_bucket'] = storageBucket;
        obj['file_directory'] = fileDirectory;
        obj['document_name'] = documentName;
        obj['last_updated_utc_unix'] = lastUpdatedUtcUnix;
    }

    /**
     * Constructs a <code>GenerateFileResponse</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/GenerateFileResponse} obj Optional instance to populate.
     * @return {module:model/GenerateFileResponse} The populated <code>GenerateFileResponse</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new GenerateFileResponse();

            if (data.hasOwnProperty('storage_bucket')) {
                obj['storage_bucket'] = ApiClient.convertToType(data['storage_bucket'], 'String');
            }
            if (data.hasOwnProperty('file_directory')) {
                obj['file_directory'] = ApiClient.convertToType(data['file_directory'], 'String');
            }
            if (data.hasOwnProperty('document_name')) {
                obj['document_name'] = ApiClient.convertToType(data['document_name'], 'String');
            }
            if (data.hasOwnProperty('last_updated_utc_unix')) {
                obj['last_updated_utc_unix'] = ApiClient.convertToType(data['last_updated_utc_unix'], 'Number');
            }
            if (data.hasOwnProperty('signed_url')) {
                obj['signed_url'] = ApiClient.convertToType(data['signed_url'], 'String');
            }
            if (data.hasOwnProperty('signed_url_generated_utc_unix')) {
                obj['signed_url_generated_utc_unix'] = ApiClient.convertToType(data['signed_url_generated_utc_unix'], 'Number');
            }
            if (data.hasOwnProperty('signed_url_expiration_in_minutes')) {
                obj['signed_url_expiration_in_minutes'] = ApiClient.convertToType(data['signed_url_expiration_in_minutes'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * the google cloud storage bucket of this file
 * @member {String} storage_bucket
 */
GenerateFileResponse.prototype['storage_bucket'] = undefined;

/**
 * the relative file location within the cloud storage bucket
 * @member {String} file_directory
 */
GenerateFileResponse.prototype['file_directory'] = undefined;

/**
 * the customer-facing file name
 * @member {String} document_name
 */
GenerateFileResponse.prototype['document_name'] = undefined;

/**
 * the unix timestamp in seconds when this file was last updated
 * @member {Number} last_updated_utc_unix
 */
GenerateFileResponse.prototype['last_updated_utc_unix'] = undefined;

/**
 * a Signed URL link of the file, optional
 * @member {String} signed_url
 */
GenerateFileResponse.prototype['signed_url'] = undefined;

/**
 * the unix timestamp in seconds when the Signed URL is generated
 * @member {Number} signed_url_generated_utc_unix
 */
GenerateFileResponse.prototype['signed_url_generated_utc_unix'] = undefined;

/**
 * how long (in minutes) the Signed URL is availiable
 * @member {Number} signed_url_expiration_in_minutes
 */
GenerateFileResponse.prototype['signed_url_expiration_in_minutes'] = undefined;






export default GenerateFileResponse;

