<template>
  <div class="text-center">

    <v-dialog
      v-model=show_popup
      width="500"
    >

    <!--
        v-model=show_popup
        :PROP_show_popup="show_popup"
        width="auto"

        v-model=show_popup
        v-model=dialog
        activator="parent"

        show_popup

        v-slot:activator="{ on, attrs }">
        v-slot:activator="{ show_popup, attrs }">
    -->
    <!--
      <template
        v-slot:activator="{ this.show_popup, attrs }">
        <div
          v-bind="attrs"
          v-on="show_popup"
        >
        </div>
      </template>
    -->


      <v-card>
        <v-card-title

        >
            <div v-html="popup_title_html_text" />
        </v-card-title>
        <!--
            {{ popup_title_text }}
        -->


        <v-divider></v-divider>


        <v-card-text

        >
            <div v-html="popup_content_html_text" />
        </v-card-text>
        <!--
            {{ popup_content_text }}
        -->

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="indigo"
                class="white--text mb-2"
                @click="on_pop_up_button_click()"
                >
                {{ popup_main_button_text }}
            </v-btn>
            <v-spacer></v-spacer>
        </v-card-actions>

      </v-card>
    </v-dialog>

  </div>
</template>


<script>



////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////


export default {
    props: {
        PROP_show_popup: Boolean,
        PROP_view_model: Object,
    },
    data() {
        return {
            CLASS_NAME: "component_popup",

            popup_title_html_text: null,
            popup_content_html_text: null,

            popup_main_button_text: null,

            show_popup: false,

        }
    },
    watch: {
        show_popup:{
            handler(newVal, oldVal) {
                //console.log("show_popup - watcher")
                //console.log("oldVal = ", oldVal)
                //console.log("newVal = ", newVal)

                if(!newVal){
                    // this happens when the pop up closes
                    this.$emit('onProceed',);
                }


            },
        },
        PROP_show_popup:{
            handler(newVal, oldVal) {
                //console.log("PROP_show_popup - watcher")
                //console.log("oldVal = ", oldVal)
                //console.log("newVal = ", newVal)

                this.show_popup = this.PROP_show_popup

                this.popup_title_html_text = this.PROP_view_model["popup_title_html_text"]
                this.popup_content_html_text = this.PROP_view_model["popup_content_html_text"]
                this.popup_main_button_text = this.PROP_view_model["popup_main_button_text"]

            },
        },

    },

    methods: {

        on_pop_up_button_click(){
            console.log("on_pop_up_BUTTON_click()")

            this.$emit('onProceed',);

        },

    },

    computed: {
        fontSizeH1() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return 'font-size: 1.15em';
                default: return 'font-size: 1.5em';
            }
        },
        fontSizeH2() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return 'font-size: 1em';
                default: return 'font-size: 1.15em';
            }
        }
    }
}

</script>