
export function isValidString(
    stringInput
) {

    if (stringInput != null
        && stringInput != undefined
        && stringInput != ""
        && stringInput != "null"
        && stringInput != "undefined"
    ) {
        return true
    }
    return false
}

export function isValidArray(
    arrayInput
) {

    if (arrayInput != null
        && arrayInput.length > 0
    ) {
        return true
    }
    return false
}
